import { Alert, Button, Form, FormGroup, Modal, Table } from "react-bootstrap";
import { useFeedback } from "../../contexts/FeedbackContext";
import { useTheme } from "../../contexts/ThemeContext";
import { useState } from "react";
import { Alert as AlertModel } from "./models/Alert";
import { useLocalization } from "../../contexts/LocalizationContext";
import { Switch } from "../common/inputs/Switch";
import { DeleteInstructions } from "../plugins/models/DeleteInstructions";

const containerStyle: React.CSSProperties = {
  position: "fixed",
  top: 50,
  right: 20
};

export const AlertsContainer = () => {
  const [alertDetails, setAlertDetails] = useState<AlertModel | null>(null);
  const [isChecked, setIsChecked] = useState(false);
  const { alerts, modal, handleClose } = useFeedback();
  const { buttonStyle } = useTheme();
  const { translate } = useLocalization();

  const handleContinue = () => {
    const furtherInstructions = isChecked ? DeleteInstructions.AllWithLegacyReactivate : undefined;
    setIsChecked(false);
    modal?.handleContinue(furtherInstructions);
  };

  return (
    <>
      {alerts.length > 0 && (
        <div className="d-flex flex-column" style={containerStyle}>
          {alerts.map((alert, i) => (
            <Alert
              key={alert.id}
              variant={alert.type}
              dismissible
              style={{ width: "500px" }}
              onClose={() => handleClose(i)}
            >
              <p className="m-0">{alert.message}</p>
              {alert.type === "danger" && (
                <Alert.Link style={{ fontSize: 14 }} onClick={() => setAlertDetails(alert)}>
                  {translate("notification.viewDetails")}
                </Alert.Link>
              )}
            </Alert>
          ))}
        </div>
      )}

      <Modal size="lg" show={!!alertDetails} onHide={() => setAlertDetails(null)}>
        <Modal.Header closeButton>
          <Modal.Title>{alertDetails?.message}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {alertDetails?.detailedMessage}
          {alertDetails?.details && (
            <Table bordered className="m-0 mt-3">
              <tbody>
                {Object.entries(alertDetails.details).map(([key, value]) => (
                  <tr key={key}>
                    <td>{key}</td>
                    <td>{`${value}`}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={() => setAlertDetails(null)}>
            {translate("common.close")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={modal?.isVisible} onHide={modal?.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{modal?.title ?? translate("notification.confirmation")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modal?.message}</Modal.Body>
        <Modal.Footer className="justify-content-between">
          <div>
            {modal?.furtherInstructions && (
              <FormGroup className="d-flex">
                <Form.Label htmlFor="furtherInstructions" className="text-nowrap m-0 me-2">
                  {modal?.furtherInstructions}
                </Form.Label>
                <Switch
                  name="furtherInstructions"
                  aria-label={modal?.furtherInstructions}
                  checked={isChecked}
                  onChange={(e) => setIsChecked(e.target.checked)}
                />
              </FormGroup>
            )}
          </div>
          <div>
            <Button variant="secondary" className="me-2" onClick={modal?.handleClose}>
              {translate("common.close")}
            </Button>
            <Button style={buttonStyle} onClick={handleContinue}>
              {translate("common.continue")}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
