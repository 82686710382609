import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik } from "formik";
import { useState } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTheme } from "../../contexts/ThemeContext";
import { UserInvitation } from "../identity/models/UserInvitation";
import { UserRole } from "../identity/models/UserRole";
import { InviteUserModal } from "./InviteUserModal";
import { inviteUserValidationSchema } from "./schemas/InviteUserValidationSchema";
import { useLocalization } from "../../contexts/LocalizationContext";

type InviteUserFormProps = {
  roles: UserRole[];
  getUsers: () => Promise<void>;
};

export const InviteUserForm = ({ roles, getUsers }: InviteUserFormProps) => {
  const { translate } = useLocalization();

  const initialValues = {
    id: "",
    email: "",
    selectedRole: { id: "", name: "" }
  };

  return (
    <Formik<UserInvitation>
      initialValues={initialValues}
      validationSchema={inviteUserValidationSchema(translate)}
      onSubmit={() => undefined}
      enableReinitialize
    >
      <InviteUserButton getUsers={getUsers} roles={roles} />
    </Formik>
  );
};

const InviteUserButton = ({ roles, getUsers }: InviteUserFormProps) => {
  const [showModal, setShowModal] = useState(false);
  const { buttonStyle } = useTheme();
  const roleItems = roles.map((x) => ({ value: x.id, text: x.name }));
  const { translate } = useLocalization();

  return (
    <div className="d-flex align-items-center mb-3">
      <Button variant="success" style={buttonStyle} onClick={() => setShowModal(true)}>
        {translate("settings.invite")}
      </Button>
      <OverlayTrigger overlay={<Tooltip>{translate("settings.inheritedAccountSettings")}</Tooltip>} placement="right">
        <FontAwesomeIcon className="ms-2 text-secondary cursor-pointer" icon={faInfoCircle} />
      </OverlayTrigger>
      {showModal && <InviteUserModal roles={roleItems} onClose={() => setShowModal(false)} getUsers={getUsers} />}
    </div>
  );
};
