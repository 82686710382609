import * as Yup from "yup";
import { TranslateFunction } from "../../../contexts/LocalizationContext";

export const pluginValidationSchema = (translate: TranslateFunction) =>
  Yup.object().shape({
    name: Yup.string().required(translate("validation.fieldRequired")),
    iconUpload: Yup.mixed().when(["publishToRWSAppStore", "mediaUrl"], {
      is: (publishToRWSAppStore: boolean, mediaUrl: string) => {
        return publishToRWSAppStore && !mediaUrl;
      },
      then: (schema) => schema.required(translate("validation.fieldRequired")),
      otherwise: (schema) => schema.notRequired()
    }),
    supportUrl: Yup.string().url(translate("validation.invalidURLFormat")),
    supportEmail: Yup.string().email(translate("validation.invalidEmail")),
    description: Yup.string()
      .required(translate("validation.fieldRequired"))
      .min(20, translate("validation.minimumCharacters")),
    categories: Yup.array().min(1, translate("validation.selectCategory")),
    parentProductDetailsId: Yup.number().min(
      1,
      translate("validation.fieldRequired")
    ),
    publishToRWSAppStore: Yup.boolean(),
    mediaUrl: Yup.string()
  });
