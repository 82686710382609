import { useAuth0 } from "@auth0/auth0-react";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { forwardRef, HTMLAttributes, useEffect, useState } from "react";
import { useConfig } from "../../contexts/ConfigContext";
import { useFetch } from "../../hooks/useFetch";
import { Button } from "react-bootstrap";
import { useLocalization } from "../../contexts/LocalizationContext";
import { extensiveNotificationsCountRoute, notificationsCountRoute } from "../../apiRoutes";

type NotificationsTogglerProps = HTMLAttributes<HTMLElement> & { isAdministratorView?: boolean };

const Component = ({ isAdministratorView, ...props }: NotificationsTogglerProps, ref: React.Ref<HTMLButtonElement>) => {
  const [count, setCount] = useState(0);
  const { configuration } = useConfig();
  const { getIdTokenClaims } = useAuth0();
  const { request } = useFetch();
  const { translate } = useLocalization();

  useEffect(() => {
    const getCount = async () => {
      const route = isAdministratorView ? extensiveNotificationsCountRoute() : notificationsCountRoute();
      await request({
        route,
        onSuccess: async (response) => setCount(await response.json())
      });
    };

    getCount();
  }, [configuration.apiUrl, getIdTokenClaims, request]);

  return (
    <Button
      {...props}
      ref={ref}
      variant="transparent"
      className="position-relative border-0 p-0 text-white"
      aria-label={translate("settings.notifications")}
    >
      <FontAwesomeIcon icon={faBell} className="cursor-pointer" />
      {count > 0 && <div style={notificationsCountStyle}>{count}</div>}
    </Button>
  );
};

export const NotificationsToggler = forwardRef(Component);

const notificationsCountStyle: React.CSSProperties = {
  top: -15,
  right: -15,
  backgroundColor: "red",
  borderRadius: "50%",
  width: 20,
  height: 20,
  position: "absolute",
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
};
