import { getIn, useFormikContext } from "formik";
import { Editor } from "../common/inputs/Editor";
import { Multiselect } from "../common/inputs/Multiselect";
import { Select } from "../common/inputs/Select";
import { EditableFieldButton } from "./EditableFieldButton";
import { PluginDetailsBase } from "./models/PluginDetailsBase";
import { Switch } from "../common/inputs/Switch";
import { usePlugin } from "./contexts/PluginContext";
import { Col, Form, OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { publicStatusMap } from "./models/StatusHelper";
import { usePluginDetailsPartial } from "./hooks/usePluginDetailsPartial";
import { useLocalization } from "../../contexts/LocalizationContext";
import { useAuth0 } from "@auth0/auth0-react";
import { ExtendedUser } from "../identity/models/ExtendedUser";

type PluginDetailsPartialProps = { hasLockedFields?: boolean };

export const PluginDetailsPartial = ({ hasLockedFields }: PluginDetailsPartialProps) => {
  const pluginContext = usePlugin();
  const formik = useFormikContext<PluginDetailsBase>();
  const pluginPartial = usePluginDetailsPartial(!!hasLockedFields);
  const { manifestResult, plugin, categories } = pluginContext;
  const { parentProducts, setManifestResultField } = pluginContext;
  const { errors, touched, values } = formik;
  const { handleChange, setFieldValue } = formik;
  const { disabledFields, handleFieldDisable } = pluginPartial;
  const { handleFileChange, handleStatusChange } = pluginPartial;
  const { translate } = useLocalization();
  const { user } = useAuth0<ExtendedUser>();
  const sharedProducts = parentProducts.filter((x) => x.value !== `${values.parentProductDetailsId}`);
  const isSystemAdmin = user?.user_metadata?.isSystemAdmin;

  return (
    <>
      <Form.Group className="d-flex mb-3 align-items-center position-relative">
        <Col xs={4}>
          <Form.Label htmlFor="name" className="m-0">
            {translate("plugins.appName")}
          </Form.Label>
          <span className="text-danger ms-1">*</span>
        </Col>
        <Col xs={8}>
          <Form.Control
            id="name"
            type="text"
            name="name"
            onChange={handleChange}
            onBlur={() => setManifestResultField("IsPluginNameMatch", true)}
            value={values?.name}
            className="w-100"
            disabled={disabledFields.name}
          />
          {touched.name && !!errors.name && <span className="invalid-feedback d-inline">{errors.name}</span>}
          {manifestResult["IsPluginNameMatch"] === false && (
            <span className="invalid-feedback d-inline text-center">{translate("plugins.invalidAppName")}</span>
          )}
        </Col>
        {hasLockedFields && isSystemAdmin && (
          <EditableFieldButton
            name="name"
            isDisabled={disabledFields.name}
            onClick={() => handleFieldDisable("name")}
          />
        )}
      </Form.Group>
      <Form.Group className="d-flex mb-3 align-items-center position-relative">
        <Col xs={4}>
          <Form.Label htmlFor="iconUpload" className="m-0">
            {translate("plugins.iconFile")}
          </Form.Label>
          <span className="text-danger ms-1">
            *
            <span aria-label={translate("validation.requiredWhenPublished")} />
          </span>
        </Col>
        <Col xs={8}>
          <Form.Control id="iconUpload" type="file" name="iconUpload" onChange={handleFileChange} className="w-100" />
          {touched.iconUpload && !!errors.iconUpload && (
            <span className="invalid-feedback d-inline">{errors.iconUpload}</span>
          )}
        </Col>
        {getIn(values, "mediaUrl") && (
          <OverlayTrigger
            placement="left"
            aria-hidden
            overlay={
              <Popover style={{ zIndex: 1000, width: 330, maxWidth: 330 }}>
                <Popover.Header as="h3">{translate("plugins.previewImage")}</Popover.Header>
                <Popover.Body>
                  <img src={getIn(values, "mediaUrl")} width={300} />
                </Popover.Body>
              </Popover>
            }
          >
            <FontAwesomeIcon style={{ right: -50 }} className="position-absolute text-warning" icon={faFileAlt} />
          </OverlayTrigger>
        )}
      </Form.Group>
      <Form.Group className="d-flex mb-3 align-items-center">
        <Col xs={4}>
          <Form.Label htmlFor="supportUrl" className="m-0">
            {translate("plugins.supportURL")}
          </Form.Label>
        </Col>
        <Col xs={8}>
          <Form.Control
            id="supportUrl"
            type="url"
            name="supportUrl"
            onChange={handleChange}
            value={values?.supportUrl}
            className="w-100"
          />
          {touched.supportUrl && !!errors.supportUrl && (
            <span className="invalid-feedback d-inline">{errors.supportUrl}</span>
          )}
        </Col>
      </Form.Group>
      <Form.Group className="d-flex mb-3 align-items-center">
        <Col xs={4}>
          <Form.Label htmlFor="supportEmail" className="m-0">
            {translate("plugins.supportEmail")}
          </Form.Label>
        </Col>
        <Col xs={8}>
          <Form.Control
            id="supportEmail"
            type="email"
            name="supportEmail"
            onChange={handleChange}
            value={values?.supportEmail}
            className="w-100"
          />
          {touched.supportEmail && !!errors.supportEmail && (
            <span className="invalid-feedback d-inline">{errors.supportEmail}</span>
          )}
        </Col>
      </Form.Group>
      <Form.Group className="d-flex mb-3 align-items-center">
        <Col xs={4} className="d-flex">
          <Form.Label htmlFor="description" className="m-0">
            {translate("plugins.description")}
            <span className="text-danger ms-1">*</span>
          </Form.Label>
        </Col>
        <Col xs={8}>
          <Editor name="description" value={values.description} />
          {touched.description && !!errors.description && (
            <span className="invalid-feedback d-inline">{errors.description}</span>
          )}
        </Col>
      </Form.Group>
      <Form.Group className="d-flex mb-3 align-items-center">
        <Col xs={4}>
          <Form.Label htmlFor="documentation" className="m-0">
            {translate("plugins.documentation")}
            <span className="text-danger ms-1">*</span>
          </Form.Label>
        </Col>
        <Col xs={8}>
          <Editor name="documentation" value={values.documentation} />
        </Col>
      </Form.Group>
      <Form.Group className="d-flex mb-3 align-items-center position-relative">
        <Col xs={4} className="d-flex">
          <Form.Label htmlFor="documentation" className="m-0">
            {translate("plugins.categories")}
            <span className="text-danger ms-1">*</span>
          </Form.Label>
        </Col>
        <Col xs={8}>
          <Multiselect
            name="categories"
            items={categories}
            values={values.categories.map((x) => x.toString())}
            size="lg"
            disabled={disabledFields.categories}
            onChange={(values) => setFieldValue("categories", values)}
          />
          {touched.categories && !!errors.categories && (
            <span className="invalid-feedback d-inline">{errors.categories}</span>
          )}
        </Col>
        {hasLockedFields && isSystemAdmin && (
          <EditableFieldButton
            name="categories"
            isDisabled={disabledFields.categories}
            onClick={() => handleFieldDisable("categories")}
          />
        )}
      </Form.Group>
      <Form.Group className="d-flex mb-3 align-items-center position-relative">
        <Col xs={4}>
          <Form.Label htmlFor="parentProductDetailsId" className="m-0">
            {translate("plugins.product")}
          </Form.Label>
          <span className="text-danger ms-1">*</span>
        </Col>
        <Col xs={8}>
          <Select
            id="parentProductDetailsId"
            name="parentProductDetailsId"
            items={parentProducts}
            value={values.parentProductDetailsId.toString()}
            onChange={handleChange}
            disabled={disabledFields.parentProductDetailsId}
          />
          {touched.parentProductDetailsId && !!errors.parentProductDetailsId && (
            <span className="invalid-feedback d-inline">{errors.parentProductDetailsId}</span>
          )}
        </Col>
        {hasLockedFields && isSystemAdmin && (
          <EditableFieldButton
            name="parentProductDetailsId"
            isDisabled={disabledFields.parentProductDetailsId}
            onClick={() => handleFieldDisable("parentProductDetailsId")}
          />
        )}
      </Form.Group>
      <Form.Group className="d-flex mb-3 align-items-center position-relative">
        <Col xs={4}>
          <p className="m-0">{translate("plugins.sharedProducts")}</p>
        </Col>
        <Col xs={8}>
          <Multiselect
            name="sharedParentProductIds"
            items={sharedProducts}
            values={values?.sharedParentProductIds.map((x) => x.toString())}
            size="lg"
            disabled={disabledFields.sharedParentProductIds}
            onChange={(values) => setFieldValue("sharedParentProductIds", values)}
          />
        </Col>
        {hasLockedFields && isSystemAdmin && (
          <EditableFieldButton
            name="sharedParentProductIds"
            isDisabled={disabledFields.sharedParentProductIds}
            onClick={() => handleFieldDisable("sharedParentProductIds")}
          />
        )}
      </Form.Group>
      <Form.Group className="d-flex mb-3 align-items-center">
        <Col xs={4}>
          <Form.Check.Label htmlFor="paidFor">{translate("plugins.chargeable")}</Form.Check.Label>
        </Col>
        <Switch
          checked={values?.paidFor}
          name="paidFor"
          id="paidFor"
          onChange={(e) => setFieldValue("paidFor", e.target.checked)}
        />
      </Form.Group>
      <Form.Group className="d-flex mb-3 align-items-center">
        <Col xs={4}>
          <Form.Check.Label htmlFor="publishToRWSAppStore">
            {translate("plugins.publishToRWSAppStore")}
          </Form.Check.Label>
        </Col>
        {plugin?.pluginVersions?.some((x) => publicStatusMap.get(x.status)) ? (
          <Switch
            checked={values?.publishToRWSAppStore}
            id="publishToRWSAppStore"
            name="publishToRWSAppStore"
            onChange={handleStatusChange}
          />
        ) : (
          <OverlayTrigger
            overlay={<Tooltip>{translate("plugins.oneVersionShouldBePublished")}</Tooltip>}
            placement="right"
          >
            <span>
              <Switch id="publishToRWSAppStore" name="publishToRWSAppStore" disabled />
            </span>
          </OverlayTrigger>
        )}
      </Form.Group>
    </>
  );
};
