import { PluginVersion } from "./PluginVersion";
import { PluginVersionBase } from "./PluginVersionBase";
import { Status } from "./Status";
import { publicStatusMap } from "./StatusHelper";

export class VersionStatusMapper {
  private statuses: Status[];
  private isSystemAdmin: boolean;
  private featureStatus: Status;

  constructor(
    siblings: PluginVersion[],
    isSystemAdmin: boolean,
    currentValues?: PluginVersionBase,
    featureStatus?: Status
  ) {
    const id = currentValues?.versionId;
    const versions = siblings.filter((x) => x.versionId !== id);

    this.isSystemAdmin = isSystemAdmin;
    this.featureStatus = featureStatus ?? this.getFeatureStatus(currentValues);
    this.statuses = versions.map((x) => x.status);
    this.statuses.push(this.featureStatus);
  }

  getMismatch(pluginStatus: Status) {
    const maximumStatus = Math.max(...this.statuses);
    const isFeatureReview = this.featureStatus === Status.InReview;

    if (publicStatusMap.get(pluginStatus) && isFeatureReview) {
      return { status: this.featureStatus, isMismatch: false };
    }

    return {
      status: this.featureStatus,
      isMismatch: pluginStatus !== maximumStatus
    };
  }

  private getFeatureStatus(version?: PluginVersionBase) {
    if (version?.publishToRWSAppStore) {
      if (this.isSystemAdmin) {
        return Status.Published;
      } else {
        return Status.InReview;
      }
    }

    if (version?.publishToPrivateAppStore) {
      return Status.Private;
    }

    return Status.InComplete;
  }
}
