import { ReactElement, createContext, useContext, useState } from "react";
import { PreserveContextProps } from "./models/PreserveContextProps";
import { useFeedback } from "./FeedbackContext";
import { removeHTMLTags, toFormData } from "../utils";
import { useLocalization } from "./LocalizationContext";

type PreserveProviderProps = { children: ReactElement[] };

export const PreserveContext = createContext<PreserveContextProps>({
  setInitialState: () => undefined,
  setCurrentState: () => undefined,
  safeExecute: () => undefined,
  reset: () => undefined
});

export const usePreserve = () => useContext(PreserveContext);

export const PreserveProvider = ({ children }: PreserveProviderProps) => {
  const [initialState, setInitialState] = useState<any>({});
  const [currentState, setCurrentState] = useState<any>({});
  const { showModal } = useFeedback();
  const { translate } = useLocalization();

  const reset = () => {
    setCurrentState({});
    setInitialState({});
  };

  const handlePreserve = (callback: () => void) => {
    showModal({
      isVisible: true,
      message: translate("versions.allUnsavedChages"),
      handleClose: () => showModal(null),
      handleContinue: async () => {
        showModal(null);
        reset();
        callback();
      }
    });
  };

  const safeExecute = (callback: () => void) => {
    const initialFormData = toFormData(initialState);
    const currentFormData = toFormData(currentState);
    const initialKeysLength = Array.from(initialFormData.keys()).length;
    const currentKeysLength = Array.from(currentFormData.keys()).length;

    if (initialKeysLength !== currentKeysLength) {
      handlePreserve(callback);
      return;
    }

    for (const key of currentFormData.keys()) {
      const current = removeHTMLTags(currentFormData.get(key));
      const initial = removeHTMLTags(initialFormData.get(key));
      const currentNoSpaces = current.replace(/\s+/g, "");
      const initialNoSpaces = initial.replace(/\s+/g, "");

      if (currentNoSpaces !== initialNoSpaces) {
        handlePreserve(callback);
        return;
      }
    }

    reset();
    callback();
  };

  return (
    <PreserveContext.Provider value={{ setCurrentState, setInitialState, safeExecute, reset }}>
      {children}
    </PreserveContext.Provider>
  );
};
