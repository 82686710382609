import { useCallback, useEffect, useState } from "react";
import { useFetch } from "../../../hooks/useFetch";
import { PushNotification } from "../models/PushNotification";
import { notificationsRoute } from "../../../apiRoutes";

export const useNotifications = () => {
  const [notifications, setNotifications] = useState<PushNotification[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { request } = useFetch();

  const getNotifications = useCallback(async () => {
    setIsLoading(true);
    await request({
      route: notificationsRoute(),
      onSuccess: async (response) => setNotifications(await response.json()),
      onAny: () => setIsLoading(false)
    });
  }, [request]);

  useEffect(() => {
    getNotifications();
  }, [getNotifications]);

  return { notifications, isLoading };
};
