import { useAuth0 } from "@auth0/auth0-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { ExtendedUser } from "../identity/models/ExtendedUser";
import { UsersGroup } from "../identity/models/UserGroup";
import { useInvite } from "./hooks/useInvite";
import { UserProfile } from "../identity/models/UserProfile";
import { faPaperPlane, faPenAlt, faSignOutAlt, faUserTimes } from "@fortawesome/free-solid-svg-icons";
import { useResize } from "../../hooks/useResize";
import { usePreserve } from "../../contexts/PreserveContext";
import { useLocalization } from "../../contexts/LocalizationContext";

type UsersTableReadonlyRowProps = {
  user: UserProfile;
  users: UserProfile[];
  index: number;
  onEdit: (id: string) => void;
  onDismiss: (id: string) => void;
};

export const UsersTableReadonlyRow = ({ user, users, index, onEdit, onDismiss }: UsersTableReadonlyRowProps) => {
  const { isLoading, handleReinvite } = useInvite();
  const { user: identity } = useAuth0<ExtendedUser>();
  const { isExpanded, onToggle } = useResize();
  const { safeExecute } = usePreserve();
  const group = new UsersGroup(identity as ExtendedUser, users);
  const isCurrentUser = identity?.user_metadata?.id === user.id;
  const { translate } = useLocalization();

  return (
    <tr className="d-flex flex-column d-xl-table-row text-center text-xl-start align-middle">
      <td className="d-none d-xl-table-cell">{index}</td>
      <td colSpan={user.isConfirmed ? 1 : 2} onClick={onToggle} className="border fw-bold fw-xl-normal">
        {user.email}
      </td>
      {isExpanded && (
        <>
          {user.isConfirmed && <td className="border">{user.name}</td>}
          <td className="border">{user.isConfirmed ? translate("common.active") : translate("common.invited")}</td>
          <td className="border">{user.selectedRole.name}</td>
          <td className="border text-center">
            <OverlayTrigger placement="top" overlay={<Tooltip>{group.getEditRoleMessage(user, translate)}</Tooltip>}>
              <Button
                className="p-0 border-0"
                variant="transparent"
                aria-label={group.getEditRoleMessage(user, translate)}
                disabled={!group.canEditUserRole(user)}
                onClick={() => safeExecute(() => onEdit(user.id))}
              >
                <FontAwesomeIcon className="text-secondary" icon={faPenAlt} />
              </Button>
            </OverlayTrigger>
          </td>
          <td className="border text-center" colSpan={group.canInviteUser(user) ? 1 : 2}>
            <OverlayTrigger placement="top" overlay={<Tooltip>{group.getDismissUserMessage(user, translate)}</Tooltip>}>
              <Button
                className="p-0 border-0"
                variant="transparent"
                aria-label={group.getDismissUserMessage(user, translate)}
                disabled={!group.canBeRemoved(user)}
                onClick={() => onDismiss(user.id)}
              >
                <FontAwesomeIcon className="text-danger" icon={isCurrentUser ? faSignOutAlt : faUserTimes} />
              </Button>
            </OverlayTrigger>
          </td>
          {group.canInviteUser(user) && (
            <td className="border text-center">
              {isLoading ? (
                <Spinner size="sm" />
              ) : (
                <OverlayTrigger overlay={<Tooltip>{translate("common.resendInvitation")}</Tooltip>}>
                  <Button
                    className="p-0"
                    variant="transparent"
                    aria-label={translate("common.resendInvitation")}
                    onClick={() => handleReinvite(user.id, user.email)}
                  >
                    <FontAwesomeIcon className="text-secondary " icon={faPaperPlane} />
                  </Button>
                </OverlayTrigger>
              )}
            </td>
          )}
        </>
      )}
    </tr>
  );
};
