import { useAuth0 } from "@auth0/auth0-react";
import { Loader } from "../components/common/progress/Loader";
import { ExtendedUser } from "../components/identity/models/ExtendedUser";
import { useUser } from "../contexts/UserContext";
import { ServerDown } from "../pages/ServerDown";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { getRoutes, systemAdminRoutes } from "./RoutesConfig";

export const App = () => {
  const { isLoading: isIdentityLoading, user } = useAuth0<ExtendedUser>();
  const { isLoading: isUserLoading, isError } = useUser();
  const basename = process.env.PUBLIC_URL;
  const routes = getRoutes(user?.user_metadata);
  const belongsToAppStore = user?.user_metadata?.belongsToAppStoreAccount;

  if (isIdentityLoading || isUserLoading) return <Loader />;
  if (isError) return <ServerDown />;
  if (belongsToAppStore) routes.push(systemAdminRoutes);
  return <RouterProvider router={createBrowserRouter(routes, { basename })} />;
};
