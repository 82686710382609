import { useNavigate } from "react-router-dom";
import { useTheme } from "../../contexts/ThemeContext";
import { usePreserve } from "../../contexts/PreserveContext";

type NavigationTabProps = {
  isSelected?: boolean;
  name: string;
  route: string;
};

export const NavigationTab = ({ isSelected, route, name }: NavigationTabProps) => {
  const { buttonStyle } = useTheme();
  const { safeExecute } = usePreserve();
  const navigate = useNavigate();
  const style = isSelected ? buttonStyle : {};

  return (
    <li className="nav-item" onClick={() => safeExecute(() => navigate(route))}>
      <h3 className="nav-link cursor-pointer m-0 fs-6" style={style}>
        {name}
      </h3>
    </li>
  );
};
