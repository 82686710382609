import { useLocation, useParams } from "react-router-dom";
import { useVersion } from "./contexts/VersionContext";
import { publishedStatusMap } from "./models/StatusHelper";
import { Status } from "./models/Status";
import { NavigationTab } from "./NavigationTab";
import { versionCommentsRoute, versionRoute } from "../../main/routes";

export const PluginVersionTabs = () => {
  const { version } = useVersion();
  const { pathname } = useLocation();
  const { pluginId, versionId } = useParams();
  const { status, publishedPluginVersion, unpublishedPluginVersion } = version;
  const isDetailsRoute = pathname === versionRoute(pluginId, versionId, Status.Published);
  const isPendingRoute = pathname === versionRoute(pluginId, versionId, Status.InReview);
  const isDraftRoute = pathname === versionRoute(pluginId, versionId, Status.InComplete);
  const isCommentsRoute = pathname === versionCommentsRoute(pluginId, versionId);
  const showPublished = publishedStatusMap.get(status) ?? publishedStatusMap.get(publishedPluginVersion?.status);
  const showPending = status === Status.InReview || unpublishedPluginVersion?.status === Status.InReview;
  const showDraft = status === Status.InComplete || unpublishedPluginVersion?.status === Status.InComplete;

  return (
    <ul className="nav nav-tabs position-relative mb-3">
      {showPublished && (
        <NavigationTab
          isSelected={isDetailsRoute}
          name="Details"
          route={versionRoute(pluginId, versionId, Status.Published)}
        />
      )}
      {showPending && (
        <NavigationTab
          isSelected={isPendingRoute}
          name="Pending"
          route={versionRoute(pluginId, versionId, Status.InReview)}
        />
      )}
      {showDraft && (
        <NavigationTab
          isSelected={isDraftRoute}
          name="Draft"
          route={versionRoute(pluginId, versionId, Status.InComplete)}
        />
      )}
      <NavigationTab isSelected={isCommentsRoute} name="Comments" route={versionCommentsRoute(pluginId, versionId)} />
    </ul>
  );
};
