import { useAuth0 } from "@auth0/auth0-react";
import { ExtendedUser } from "../components/identity/models/ExtendedUser";
import { useFetch } from "../hooks/useFetch";
import { UserContextProps } from "./models/UserContextProps";
import { createContext, ReactElement, useState, useEffect, useContext, useCallback } from "react";
import { logoutRoute, oneUserProfileRoute } from "../apiRoutes";

type UserProviderProps = { children: ReactElement };

export const UserContext = createContext<UserContextProps>({
  isLoading: true,
  isError: false,
  logout: () => undefined,
  getUser: async () => undefined
});

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }: UserProviderProps) => {
  const { user, logout: auth0Logout, isAuthenticated, isLoading: isIdentityLoding } = useAuth0<ExtendedUser>();
  const { request } = useFetch();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const returnTo = window.location.origin + process.env.PUBLIC_URL;
  const options = { logoutParams: { returnTo } };

  const getUser = useCallback(async () => {
    setIsLoading(true);

    await request({
      route: oneUserProfileRoute(),
      onSuccess: async (response) => {
        if (user) user.user_metadata = await response.json();
      },
      onFailure: async () => {
        if (user) user.user_metadata = undefined;
      },
      onError: async () => {
        if (user) user.user_metadata = undefined;
        setIsError(true);
      },
      onAny: () => setIsLoading(false)
    });
  }, [user, request]);

  useEffect(() => {
    if (isAuthenticated) getUser();
    if (!isIdentityLoding && !isAuthenticated) setIsLoading(false);
  }, [getUser, isAuthenticated, isIdentityLoding]);

  const logout = useCallback(async () => {
    setIsLoading(true);
    await request({
      method: "POST",
      route: logoutRoute(),
      onAny: () => auth0Logout(options)
    });
  }, [setIsLoading, auth0Logout, request]);

  return <UserContext.Provider value={{ isLoading, isError, logout, getUser }}>{children}</UserContext.Provider>;
};
