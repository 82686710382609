import { productsRoute } from "../../../apiRoutes";
import { useFetch } from "../../../hooks/useFetch";
import { ProductsResponse } from "../models/ProductsResponse";
import { ProductsContextProps } from "./models/ProductsContextProps";
import { createContext, ReactElement, useCallback, useContext, useEffect, useState } from "react";

type ProductsContextProviderProps = { children: ReactElement };

const ProductsContext = createContext<ProductsContextProps>({
  productDetails: [],
  parentProducts: [],
  isLoading: true,
  editModeId: -1,
  capacity: 10,
  onCapacityChange: () => undefined,
  onEditModeIdChange: () => undefined,
  getProducts: async () => undefined
});

export const useProducts = () => useContext(ProductsContext);

export const ProductsContextProvider = ({ children }: ProductsContextProviderProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [editModeId, setEditModeId] = useState(-1);
  const [capacity, setCapacity] = useState(10);
  const [data, setData] = useState({} as ProductsResponse);
  const { request } = useFetch();

  const getProducts = useCallback(async () => {
    setIsLoading(true);
    await request({
      route: productsRoute(),
      onSuccess: async (response) => setData(await response.json()),
      onAny: () => setIsLoading(false)
    });
  }, [request]);

  useEffect(() => {
    getProducts();
  }, [getProducts]);

  const onEditModeIdChange = (id: number) => setEditModeId(id);
  const onCapacityChange = (capacity: number) => setCapacity((prev) => prev + capacity);

  return (
    <ProductsContext.Provider
      value={{
        ...data,
        isLoading,
        editModeId,
        capacity,
        onCapacityChange,
        onEditModeIdChange,
        getProducts
      }}
    >
      {children}
    </ProductsContext.Provider>
  );
};
