import { useEffect, useState } from "react";
import { DisabledFields } from "../models/DisabledFields";
import { PluginVersionBase } from "../models/PluginVersionBase";
import { useFeedback } from "../../../contexts/FeedbackContext";
import { useFormikContext } from "formik";
import { useLocalization } from "../../../contexts/LocalizationContext";

export const useVersionDetailsPartial = (hasLockedFields: boolean) => {
  const [disabledFields, setDisabledFields] = useState<DisabledFields>({});
  const { values, setFieldValue } = useFormikContext<PluginVersionBase>();
  const { showModal } = useFeedback();
  const { translate } = useLocalization();

  useEffect(() => {
    setDisabledFields(new DisabledFields(hasLockedFields));
  }, [hasLockedFields]);

  const handleFieldDisable = (field: keyof DisabledFields) => {
    setDisabledFields((prev) => ({ ...prev, [field]: !prev[field] }));
  };

  const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>, opositeField: keyof PluginVersionBase) => {
    const checked = e.target.checked;
    const name = opositeField.charAt(0).toUpperCase() + opositeField.slice(1);
    const fieldName = e.target.name as keyof PluginVersionBase;

    if (checked && values[opositeField]) {
      showModal({
        isVisible: true,
        message: translate("versions.disableNameOption", { name: name }),
        handleClose: () => showModal(null),
        handleContinue: () => {
          showModal(null);
          setFieldValue(fieldName, checked);
          setFieldValue(opositeField, false);
        }
      });
    } else {
      setFieldValue(fieldName, checked);
    }
  };

  return { disabledFields, handleFieldDisable, handleSwitchChange };
};
