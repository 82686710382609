import { useFetch } from "../../../hooks/useFetch";
import { ParentProductsContextProps } from "./models/ParentProductsContextProps";
import { ParentProductsResponse } from "../models/ParentProductsResponse";
import { createContext, ReactElement, useCallback, useContext, useEffect, useState } from "react";
import { parentProductsRoute } from "../../../apiRoutes";

type ParentProductsContextProviderProps = { children: ReactElement };

const ParentProductContext = createContext<ParentProductsContextProps>({
  parentProducts: [],
  fileExtensions: [],
  isLoading: true,
  editModeId: -1,
  capacity: 10,
  onEditModeIdChange: () => undefined,
  getParentProducts: async () => undefined,
  onCapacityChange: () => undefined
});

export const useParentProducts = () => useContext(ParentProductContext);

export const ParentProductsContextProvider = ({ children }: ParentProductsContextProviderProps) => {
  const [data, setData] = useState({} as ParentProductsResponse);
  const [capacity, setCapacity] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [editModeId, setEditModeId] = useState(-1);
  const { request } = useFetch();

  const getParentProducts = useCallback(async () => {
    setIsLoading(true);
    await request({
      route: parentProductsRoute(),
      onSuccess: async (response) => setData(await response.json()),
      onAny: () => setIsLoading(false)
    });
  }, [request]);

  useEffect(() => {
    getParentProducts();
  }, [getParentProducts]);

  const onEditModeIdChange = (id: number) => setEditModeId(id);
  const onCapacityChange = (capacity: number) => setCapacity((prev) => prev + capacity);

  return (
    <ParentProductContext.Provider
      value={{
        ...data,
        isLoading,
        editModeId,
        capacity,
        onEditModeIdChange,
        onCapacityChange,
        getParentProducts
      }}
    >
      {children}
    </ParentProductContext.Provider>
  );
};
