import { useState } from "react";
import { useFeedback } from "../../../contexts/FeedbackContext";
import { useFetch } from "../../../hooks/useFetch";
import { useCategories } from "../contexts/CategoriesContext";
import { CategoryDetails } from "../models/CategoryDetails";
import { toFormData } from "../../../utils";
import { useLocalization } from "../../../contexts/LocalizationContext";
import { deleteCategoryRoute, updateCategoryRoute } from "../../../apiRoutes";

export const useCategoriesActions = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { getCategories, onEditModeIdChange } = useCategories();
  const { pushNotification, showModal } = useFeedback();
  const { request } = useFetch();
  const { translate } = useLocalization();

  const handleDelete = (id: number) => {
    const handleContinue = async () => {
      showModal(null);
      setIsLoading(true);

      await request({
        route: deleteCategoryRoute(id),
        method: "DELETE",
        onSuccess: async () => {
          await getCategories();
          pushNotification({ message: translate("plugins.categoryDeletedSuccessfully"), type: "success" });
        },
        onFailure: () => pushNotification({ message: translate("plugins.failedToDeleteCategory"), type: "danger" }),
        onAny: () => setIsLoading(false)
      });
    };

    showModal({
      isVisible: true,
      message: translate("plugins.removeThisCategory"),
      handleClose: () => showModal(null),
      handleContinue
    });
  };

  const handleSubmit = async (value: CategoryDetails) => {
    setIsLoading(true);
    await request({
      route: updateCategoryRoute(),
      body: toFormData(value),
      method: "POST",
      onSuccess: async () => {
        onEditModeIdChange(-1);
        await getCategories();
        pushNotification({ message: translate("plugins.categorySavedSuccessfully"), type: "success" });
      },
      onFailure: () => pushNotification({ message: translate("plugins.categorySavingFailed"), type: "danger" }),
      onAny: () => setIsLoading(false)
    });
  };

  return {
    isLoading,
    handleSubmit,
    handleDelete
  };
};
