import { useAuth0 } from "@auth0/auth0-react";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Form, InputGroup } from "react-bootstrap";
import { useTheme } from "../../contexts/ThemeContext";
import { ExtendedUser } from "../identity/models/ExtendedUser";
import { useLogs } from "./hooks/useLogs";
import { useLocalization } from "../../contexts/LocalizationContext";
import { PluginLogsFilterActionType } from "./models/PluginLogsFilterActionType";
import { PluginLogsFilters } from "./models/PluginLogsFilters";

type LogsToolbarProps = {
  filters: PluginLogsFilters;
  handleFiltersChange: (action: PluginLogsFilterActionType, value?: string) => void;
};

export const LogsToolbar = ({ filters, handleFiltersChange }: LogsToolbarProps) => {
  const { user } = useAuth0<ExtendedUser>();
  const { buttonStyle } = useTheme();
  const { handleClearAll } = useLogs();
  const isSystemAdmin = user?.user_metadata?.isSystemAdmin;
  const { translate } = useLocalization();

  return (
    <>
      <Col xs={7} className="d-flex">
        <div className="d-flex align-items-center me-2">
          <p className="m-0 me-2">{translate("common.from")}</p>
          <Form.Control
            type="date"
            value={filters.FromDate}
            aria-label={translate("plugins.searchDateFrom")}
            onChange={(e) => handleFiltersChange(PluginLogsFilterActionType.SetFromDate, e.target.value)}
          />
        </div>
        <div className="d-flex align-items-center">
          <p className="m-0 me-2">{translate("common.to")}</p>
          <Form.Control
            type="date"
            value={filters.ToDate}
            aria-label={translate("plugins.searchDateTo")}
            onChange={(e) => handleFiltersChange(PluginLogsFilterActionType.SetToDate, e.target.value)}
          />
        </div>
        <Button variant="transparent" aria-label={translate("plugins.searchLogs")}>
          <FontAwesomeIcon icon={faSearch} />
        </Button>
      </Col>
      <Col xs={isSystemAdmin ? 3 : 5}>
        <InputGroup>
          <Form.Control
            id="search"
            placeholder={translate("plugins.search")}
            value={filters.Query}
            onChange={(e) => handleFiltersChange(PluginLogsFilterActionType.SetQuery, e.target.value)}
          />
          <InputGroup.Text>
            <Button variant="transparent p-0" aria-label={translate("plugins.search")}>
              <FontAwesomeIcon icon={faSearch} />
            </Button>
          </InputGroup.Text>
        </InputGroup>
      </Col>
      {isSystemAdmin && (
        <Col xs={2}>
          <Button variant="success" onClick={handleClearAll} style={buttonStyle}>
            {translate("common.clearAllFilters")}
          </Button>
        </Col>
      )}
    </>
  );
};
