import { Button, Spinner } from "react-bootstrap";
import { useTheme } from "../../contexts/ThemeContext";
import { useVersion } from "./contexts/VersionContext";
import { useVersionActions } from "./hooks/useVersionActions";
import { useFormikContext } from "formik";
import { PluginVersionBase } from "./models/PluginVersionBase";
import { useLocalization } from "../../contexts/LocalizationContext";

export const DownloadVersionButtonGroup = () => {
  const { initialValues } = useFormikContext<PluginVersionBase>();
  const { isLoading, download } = useVersionActions(initialValues);
  const { version } = useVersion();
  const { buttonStyle } = useTheme();
  const { translate } = useLocalization();

  const handleClick = async () => {
    if (version.isNavigationLink) {
      window.open(version?.downloadDetails?.downloadUrl, "__blank__");
    } else {
      await download(version.versionId);
    }
  };

  return (
    <Button type="button" onClick={handleClick} disabled={isLoading} style={{ ...buttonStyle, minWidth: 120 }}>
      {isLoading ? (
        <Spinner size="sm" />
      ) : version.isNavigationLink ? (
        translate("versions.navigate")
      ) : (
        translate("versions.download")
      )}
    </Button>
  );
};
