import { Form, Formik } from "formik";
import { PluginVersionBase } from "./models/PluginVersionBase";
import { versionValidationSchema } from "./schemas/VersionValidationSchema";
import { usePlugin } from "./contexts/PluginContext";
import { useAuth0 } from "@auth0/auth0-react";
import { ExtendedUser } from "../identity/models/ExtendedUser";
import { PluginVersion } from "./models/PluginVersion";
import { ReactNode } from "react";
import { useLocalization } from "../../contexts/LocalizationContext";

type VersionDetailsFormProps = { version?: PluginVersion; children: ReactNode };

export const VersionDetailsForm = ({ version, children }: VersionDetailsFormProps) => {
  const { plugin } = usePlugin();
  const { user } = useAuth0<ExtendedUser>();
  const { translate } = useLocalization();
  const selectedAccount = user?.user_metadata?.selectedAccount;

  const initialValues = {
    versionId: version?.versionId ?? -1,
    versionNumber: version?.versionNumber ?? "",
    versionUpload: null,
    downloadDetails: {
      downloadUrl: version?.downloadDetails?.downloadUrl ?? ""
    },
    minimumRequiredVersionOfStudio: version?.minimumRequiredVersionOfStudio ?? "",
    maximumRequiredVersionOfStudio: version?.maximumRequiredVersionOfStudio ?? "",
    fileHash: version?.fileHash ?? "",
    changelog: version?.changelog ?? "",
    supportedProducts: version?.supportedProducts ?? [],
    publishToRWSAppStore: version?.publishToRWSAppStore ?? selectedAccount?.publishToRWSAppStore,
    publishToPrivateAppStore: version?.publishToPrivateAppStore ?? selectedAccount?.publishToPrivateAppStore,
    isNavigationLink: version?.isNavigationLink ?? plugin?.isLanguageCloudPlugin,
    validateVersionUpload: !plugin?.isLanguageCloudPlugin && !version?.isNavigationLink,
    versionUploadRequired: !version?.downloadDetails?.downloadUrl,
    validateVersionBoundary: plugin?.hasStudioVersionSupport
  } as PluginVersionBase & {
    validateVersionUpload: boolean;
    versionUploadRequired: boolean;
    validateVersionBoundary: boolean;
  };

  return (
    <Formik<PluginVersionBase>
      validationSchema={versionValidationSchema(translate)}
      initialValues={initialValues}
      onSubmit={() => undefined}
      enableReinitialize
      validateOnChange
    >
      <Form>{children}</Form>
    </Formik>
  );
};
