import { useAuth0 } from "@auth0/auth0-react";
import { useFormikContext } from "formik";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { useTheme } from "../../contexts/ThemeContext";
import { Loader } from "../common/progress/Loader";
import { ExtendedUser } from "../identity/models/ExtendedUser";
import { usePlugin } from "./contexts/PluginContext";
import { useVersionActions } from "./hooks/useVersionActions";
import { InformationMissingModal } from "./InformationMissingModal";
import { PluginVersionBase } from "./models/PluginVersionBase";
import { draftVersionValidationSchema } from "./schemas/DraftVersionValidationSchema";
import { versionValidationSchema } from "./schemas/VersionValidationSchema";
import { useLocalization } from "../../contexts/LocalizationContext";
import { Action } from "./models/Action";

export const DraftVersionButtonGroup = () => {
  const [showModal, setShowModal] = useState(false);
  const formikContext = useFormikContext<PluginVersionBase>();
  const { values, initialValues, setTouched, handleSubmit } = formikContext;
  const { user } = useAuth0<ExtendedUser>();
  const { isLoading, handleSubmit: submit } = useVersionActions(initialValues);
  const { plugin } = usePlugin();
  const { buttonStyle } = useTheme();
  const isSystemAdmin = user?.user_metadata?.isSystemAdmin;
  const { translate } = useLocalization();

  const handleSave = async () => {
    try {
      await versionValidationSchema(translate).validate(values, { abortEarly: false });
      if (values.publishToRWSAppStore && plugin?.hasMissingInformation) {
        setShowModal(true);
      } else {
        setTouched({});
        if (isSystemAdmin) await submit(values, Action.Save);
        else await submit(values, Action.Submit);
      }
    } catch (errors) {
      handleSubmit();
    }
  };

  const handleSaveAsDraft = async () => {
    try {
      await draftVersionValidationSchema(translate).validate(values, {
        abortEarly: false
      });
      setTouched({});
      submit(values, Action.SaveAsDraft);
    } catch (errors) {
      setTouched({ versionNumber: true, supportedProducts: true });
    }
  };

  const handleCloseModal = () => setShowModal(false);

  if (isLoading) return <Loader />;

  return (
    <>
      <Button style={buttonStyle} type="button" onClick={handleSave}>
        {values.publishToRWSAppStore
          ? isSystemAdmin
            ? translate("plugins.publish")
            : translate("plugins.submit")
          : translate("common.save")}
      </Button>
      <Button variant="outline-secondary" type="button" className="mx-2" onClick={handleSaveAsDraft}>
        {translate("plugins.saveAsDraft")}
      </Button>
      {showModal && <InformationMissingModal onClose={handleCloseModal} />}
    </>
  );
};
