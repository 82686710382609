import { useFetch } from "../../../hooks/useFetch";
import { CategoriesContextProps } from "./models/CategoriesContextProps";
import { CategoryDetails } from "../models/CategoryDetails";
import { createContext, ReactElement, useCallback, useContext, useEffect, useState } from "react";
import { categoriesRoute } from "../../../apiRoutes";

type CategoriesContextProviderProps = { children: ReactElement };

const CategoriesContext = createContext<CategoriesContextProps>({
  categories: [],
  isLoading: true,
  editModeId: -1,
  capacity: 10,
  getCategories: async () => undefined,
  onEditModeIdChange: () => undefined,
  onCapacityChange: () => undefined
});

export const useCategories = () => useContext(CategoriesContext);

export const CategoriesContextProvider = ({ children }: CategoriesContextProviderProps) => {
  const [editModeId, setEditModeId] = useState(-1);
  const [isLoading, setIsLoading] = useState(true);
  const [capacity, setCapacity] = useState(10);
  const [categories, setCategories] = useState<CategoryDetails[]>([]);
  const { request } = useFetch();

  const getCategories = useCallback(async () => {
    setIsLoading(true);
    await request({
      route: categoriesRoute(),
      onSuccess: async (response) => setCategories(await response.json()),
      onAny: () => setIsLoading(false)
    });
  }, [request]);

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  const onEditModeIdChange = (id: number) => setEditModeId(id);
  const onCapacityChange = (capacity: number) => setCapacity((prev) => prev + capacity);

  return (
    <CategoriesContext.Provider
      value={{
        categories,
        isLoading,
        editModeId,
        capacity,
        onCapacityChange,
        onEditModeIdChange,
        getCategories
      }}
    >
      {children}
    </CategoriesContext.Provider>
  );
};
