import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFeedback } from "../../../contexts/FeedbackContext";
import { useFetch } from "../../../hooks/useFetch";
import { usePlugin } from "../contexts/PluginContext";
import { DeleteInstructions } from "../models/DeleteInstructions";
import { PluginVersionBase } from "../models/PluginVersionBase";
import { Status } from "../models/Status";
import { sequencesEqual, toFormData } from "../../../utils";
import { useDownload } from "../../../hooks/useDownload";
import { VersionStatusMapper } from "../models/VersionStatusMapper";
import { useLocalization } from "../../../contexts/LocalizationContext";
import { publicStatusMap } from "../models/StatusHelper";
import { useAuth0 } from "@auth0/auth0-react";
import { ExtendedUser } from "../../identity/models/ExtendedUser";
import { Action, actionStatusMap } from "../models/Action";
import { versionIndexRoute, versionRoute } from "../../../main/routes";
import { DeleteAction } from "../models/DeleteAction";
import {
  downloadPluginVersionRoute,
  pluginVersionDeleteRoute,
  pluginVersionRequestRoute,
  pluginVersionStatusRoute,
  savePluginVersionRoute
} from "../../../apiRoutes";

export const useVersionActions = (initial?: PluginVersionBase) => {
  const [isLoading, setIsLoading] = useState(false);
  const { pushNotification, showModal } = useFeedback();
  const { request } = useFetch();
  const { getPlugin, onManifestResultChange } = usePlugin();
  const { pluginId } = useParams();
  const { handleDownload } = useDownload();
  const { plugin } = usePlugin();
  const { translate } = useLocalization();
  const navigate = useNavigate();
  const { pluginVersions: versions } = plugin;
  const { user } = useAuth0<ExtendedUser>();
  const isSystemAdmin = user?.user_metadata?.isSystemAdmin;

  const handleDelete = (
    versionId: number,
    instructions: DeleteInstructions,
    action: DeleteAction,
    isPatch?: boolean
  ) => {
    const formData = new FormData();

    const onSuccess = async (response: Response) => {
      if (response.status === 200) {
        const json = await response.json();
        const versionId = json.publishedPluginVersionId ?? json.versionId;
        navigate(versionRoute(pluginId, versionId, json.status));
      } else navigate(versionIndexRoute(pluginId));

      await getPlugin();

      pushNotification({
        message: translate(`versions.deleteActions.${action}`),
        type: "success"
      });
    };

    const handleContinue = async (deleteInstructions?: DeleteInstructions) => {
      formData.set("deleteInstructions", `${deleteInstructions ?? instructions}`);
      showModal(null);
      setIsLoading(true);
      await request({
        route: pluginVersionDeleteRoute(pluginId, versionId, action),
        method: isPatch ? "PATCH" : "DELETE",
        body: formData,
        onSuccess,
        onFailure: async (response) => {
          const json = await response.json();
          pushNotification({ ...json, type: "danger" });
        },
        onAny: () => setIsLoading(false)
      });
    };

    if (action === DeleteAction.RejectDelete) handleContinue();
    else {
      showModal({
        isVisible: true,
        message: translate("versions.removePluginVersion"),
        furtherInstructions: showInstructions(instructions, versionId, action)
          ? translate("versions.reactivateLegacy")
          : "",
        handleClose: () => showModal(null),
        handleContinue
      });
    }
  };

  const changeStatus = async (featureStatus: Status, id: number) => {
    const onSuccess = async (response: Response) => {
      const json = await response.json();
      navigate(versionRoute(pluginId, json.versionId, json.status));
      await getPlugin();
      pushNotification({
        message: translate("versions.versionStatusChangedSuccessfully"),
        type: "success"
      });
    };

    const createRequest = async () => {
      setIsLoading(true);
      await request({
        route: pluginVersionStatusRoute(pluginId, id, featureStatus),
        method: "PATCH",
        onSuccess,
        onFailure: () =>
          pushNotification({
            message: translate("versions.failedToChangeVersionStatus"),
            type: "danger"
          }),
        onAny: () => setIsLoading(false)
      });
    };

    const mapper = new VersionStatusMapper(versions, !!isSystemAdmin, initial, featureStatus);
    const result = mapper.getMismatch(plugin.status);

    if (result.isMismatch) {
      showModal({
        isVisible: true,
        message: translate("versions.statusMismatchWarning", {
          status: Status[result.status]
        }),
        handleClose: () => showModal(null),
        handleContinue: async () => {
          showModal(null);
          await createRequest();
        }
      });
    } else {
      await createRequest();
    }
  };

  const handleRequest = async (values: PluginVersionBase, status: Status, rejectionReason: string) => {
    const formData = toFormData(values);
    formData.set("rejectionReason", `${rejectionReason}`);

    const onSuccess = async (response: Response) => {
      const json = await response.json();
      const versionId = json.publishedPluginVersionId ?? json.versionId;
      await getPlugin();
      navigate(versionRoute(pluginId, versionId, json.status));
      pushNotification({
        message: translate("versions.versionStatus"),
        type: "success"
      });
    };

    const createRequest = async () => {
      setIsLoading(true);
      await request({
        route: pluginVersionRequestRoute(pluginId, status),
        method: "PATCH",
        body: formData,
        onSuccess,
        onFailure: async (response) => {
          const json = await response.json();
          pushNotification({ ...json, type: "danger" });
        },
        onAny: () => setIsLoading(false)
      });
    };

    if (status === Status.Published && !publicStatusMap.get(plugin.status)) {
      showModal({
        isVisible: true,
        message: translate("versions.statusMismatchWarning", {
          status: Status[Status.Published]
        }),
        handleClose: () => showModal(null),
        handleContinue: async () => {
          showModal(null);
          await createRequest();
        }
      });
    } else {
      await createRequest();
    }
  };

  const handleSubmit = async (values: PluginVersionBase, action: Action) => {
    const onSuccess = async (response: Response) => {
      const json = await response.json();
      const versionId = json.publishedPluginVersionId ?? json.versionId;
      await getPlugin();
      navigate(versionRoute(pluginId, versionId, json.status));
      pushNotification({
        message: translate("versions.pluginVersionSavedSuccessfully"),
        type: "success"
      });
    };

    const onFailure = async (response: Response) => {
      const json = await response.json();
      if (json.details) onManifestResultChange(json.details);
      pushNotification({ ...json, type: "danger" });
    };

    const createRequest = async () => {
      setIsLoading(true);
      await request({
        route: savePluginVersionRoute(pluginId, action),
        method: "POST",
        body: toFormData(values),
        onSuccess,
        onFailure,
        onAny: () => setIsLoading(false)
      });
    };

    const mapper = new VersionStatusMapper(versions, !!isSystemAdmin, values, actionStatusMap.get(action));
    const result = mapper.getMismatch(plugin.status);

    if (result?.isMismatch) {
      showModal({
        isVisible: true,
        message: translate("versions.statusMismatchWarning", {
          status: Status[result.status]
        }),
        handleClose: () => showModal(null),
        handleContinue: async () => {
          showModal(null);
          await createRequest();
        }
      });
    } else await createRequest();
  };

  const download = async (id: number) => {
    setIsLoading(true);
    await handleDownload(downloadPluginVersionRoute(pluginId, id));
    setIsLoading(false);
  };

  const showInstructions = (instructions: DeleteInstructions, versionId: number, action: DeleteAction) => {
    if (action === DeleteAction.ApproveDelete) return false;

    const version = versions.find((x) => x.versionId === versionId);
    const clone = version?.publishedPluginVersion ?? version?.unpublishedPluginVersion;
    const hasInstructions = [DeleteInstructions.All, DeleteInstructions.AllWithLegacyReactivate].includes(instructions);
    const hasLegacy = versions
      .filter((x) => x.versionId !== version?.versionId)
      .some((x) => sequencesEqual(x.supportedProducts, version?.supportedProducts ?? []));

    return version?.status === Status.Published && hasLegacy && (hasInstructions || !clone);
  };

  return {
    isLoading,
    download,
    handleSubmit,
    changeStatus,
    handleDelete,
    handleRequest
  };
};
