import { useAuth0 } from "@auth0/auth0-react";
import { useFormikContext } from "formik";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTheme } from "../../contexts/ThemeContext";
import { Loader } from "../common/progress/Loader";
import { ExtendedUser } from "../identity/models/ExtendedUser";
import { usePluginActions } from "./hooks/usePluginActions";
import { PluginDetails } from "./models/PluginDetails";
import { PluginDetailsBase } from "./models/PluginDetailsBase";
import { Status } from "./models/Status";
import { pluginValidationSchema } from "./schemas/PluginValidationSchema";
import { useLocalization } from "../../contexts/LocalizationContext";
import { Action } from "./models/Action";

type PublishedPluginButtonGroupProps = { plugin: PluginDetails };

export const PublishedPluginButtonGroup = ({ plugin }: PublishedPluginButtonGroupProps) => {
  const { buttonStyle } = useTheme();
  const { user } = useAuth0<ExtendedUser>();
  const { isLoading, changeStatus, handleSubmit: submit } = usePluginActions();
  const isSystemAdmin = user?.user_metadata?.isSystemAdmin;
  const formikContext = useFormikContext<PluginDetailsBase>();
  const { values, setTouched, handleSubmit } = formikContext;
  const { translate } = useLocalization();

  const handleSave = async () => {
    try {
      await pluginValidationSchema(translate).validate(values, { abortEarly: false });
      setTouched({});
      if (isSystemAdmin) await submit(values, Action.Save);
      else await submit(values, Action.Submit);
    } catch (errors) {
      handleSubmit();
    }
  };

  if (isLoading) return <Loader />;

  return (
    <>
      <Button variant="success" className="me-2" type="button" style={buttonStyle} onClick={handleSave}>
        {values.publishToRWSAppStore && !isSystemAdmin ? translate("plugins.submit") : translate("common.save")}
      </Button>
      {plugin.status === Status.Published && (
        <Button
          variant="warning"
          className="me-2"
          type="button"
          onClick={() => changeStatus(Status.NotPublished, plugin.id)}
        >
          {translate("plugins.deactivate")}
        </Button>
      )}
      {plugin.status === Status.NotPublished && (
        <OverlayTrigger overlay={<Tooltip>{translate("versions.oneVersionShouldBeActive")}</Tooltip>}>
          <span className="d-inline-block">
            <Button
              variant="warning"
              className="me-2"
              disabled
              onClick={() => changeStatus(Status.Published, plugin.id)}
            >
              {translate("plugins.activate")}
            </Button>
          </span>
        </OverlayTrigger>
      )}
    </>
  );
};
