import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFeedback } from "../../../contexts/FeedbackContext";
import { useFetch } from "../../../hooks/useFetch";
import { ExtendedUser } from "../../identity/models/ExtendedUser";
import { UserGrant } from "../../identity/models/UserGrant";
import { UsersResponse } from "../models/UsersResponse";
import { useLocalization } from "../../../contexts/LocalizationContext";
import { allUserProfilesRoute, removeUserProfileRoute, updateUserProfileRoleRoute } from "../../../apiRoutes";

export const useUsers = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<UsersResponse>();
  const [editModeId, setEditModeId] = useState("");
  const { pushNotification, showModal } = useFeedback();
  const { user } = useAuth0<ExtendedUser>();
  const { request } = useFetch();
  const navigate = useNavigate();
  const { translate } = useLocalization();
  const accountId = user?.user_metadata?.selectedAccount?.id;

  const getUsers = useCallback(async () => {
    setIsLoading(true);
    await request({
      route: allUserProfilesRoute(accountId),
      onSuccess: async (response) => setData(await response.json()),
      onAny: () => setIsLoading(false)
    });
  }, [accountId, request]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const handleSubmit = async (value: UserGrant) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("userId", value.id);
    formData.append("selectedRoleId", value.selectedRole.id);

    await request({
      route: updateUserProfileRoleRoute(),
      body: formData,
      method: "PATCH",
      onSuccess: async () => {
        await getUsers();
        pushNotification({
          message: translate("request.account.roleChangeSuccess", {
            email: value.email ?? "",
            selectedAccountName: user?.user_metadata?.selectedAccount.name ?? "",
            roleName: data?.userRoles.find((role) => role.id === value.selectedRole.id)?.name ?? ""
          }),
          type: "success"
        });
      },
      onFailure: () =>
        pushNotification({
          message: translate("request.account.roleChangeFail", { email: value.email ?? "" }),
          type: "danger"
        }),
      onAny: () => {
        setIsLoading(false);
        setEditModeId("");
      }
    });
  };

  const handleLeave = (id: string) => {
    const isCurrentUser = user?.user_metadata?.id === id;
    const accountName = user?.user_metadata?.selectedAccount.name ?? "";
    const email = data?.userProfiles.find((user) => user.id === id)?.email ?? "";

    const handleContinue = async () => {
      showModal(null);
      setIsLoading(true);
      await request({
        route: removeUserProfileRoute(id),
        method: "DELETE",
        onSuccess: async () => {
          if (isCurrentUser) navigate("/accounts");
          else await getUsers();
          pushNotification({
            message: isCurrentUser
              ? translate("request.account.userDismissSelfSuccess", {
                  accountName
                })
              : translate("request.account.userDismissSuccess", {
                  email
                }),
            type: "success"
          });
        },
        onFailure: async () =>
          pushNotification({
            message: isCurrentUser
              ? translate("request.account.userDismissSelfFail", {
                  accountName
                })
              : translate("request.account.userDismissFail", {
                  email
                }),
            type: "danger"
          }),
        onAny: () => {
          setIsLoading(false);
        }
      });
    };

    showModal({
      isVisible: true,
      message: isCurrentUser ? translate("account.leaveThisAccount") : translate("account.removeThisUser"),
      handleClose: () => showModal(null),
      handleContinue
    });
  };

  return {
    isLoading,
    data,
    editModeId,
    setEditModeId,
    handleSubmit,
    handleLeave,
    getUsers
  };
};
