import { useAuth0 } from "@auth0/auth0-react";
import { Col, Row } from "react-bootstrap";
import { SideNavbar } from "../common/navigation/SideNavbar";
import { ExtendedUser } from "../identity/models/ExtendedUser";
import { Roles } from "../identity/models/UserRole";
import { Outlet } from "react-router-dom";
import { MatchAlgorithm } from "../common/navigation/models/MatchAlgorithm";
import { TranslateFunction, useLocalization } from "../../contexts/LocalizationContext";
import {
  accountExportRoute,
  accountNameMappingsRoute,
  accountRoute,
  accountSettingsRoute,
  accountUsersRoute
} from "../../main/routes";

const grantedRoles: string[] = [Roles.SystemAdministrator, Roles.Administrator];

const accountSettingsSidebarItems = (translate: TranslateFunction) => [
  { name: translate("settings.account"), route: accountRoute() },
  { name: translate("settings.users"), route: accountUsersRoute() },
  { name: translate("settings.settings"), route: accountSettingsRoute(), grantedRoles },
  { name: translate("settings.export"), route: accountExportRoute(), grantedRoles },
  { name: translate("settings.renamedApps"), route: accountNameMappingsRoute(), grantedRoles }
];

export const Layout = () => {
  const { user } = useAuth0<ExtendedUser>();
  const { translate } = useLocalization();
  const role = user?.user_metadata?.selectedRole.name;
  const filteredItems = accountSettingsSidebarItems(translate).filter(
    (x) => !x.grantedRoles || x.grantedRoles?.some((y) => y === role)
  );

  return (
    <>
      <h1 aria-hidden>{translate("settings.accountSettings")}</h1>
      <h2>{translate("settings.manageAccountSettings")}</h2>
      <hr className="w-100" aria-hidden></hr>
      <Row>
        <SideNavbar items={filteredItems} matchAlgorithm={MatchAlgorithm.Equals} />
        <Col xs={12} xl={9}>
          <div className="h-100">{<Outlet />}</div>
        </Col>
      </Row>
    </>
  );
};
