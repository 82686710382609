import { useLocation } from "react-router-dom";
import { usePlugin } from "./contexts/PluginContext";
import { NavigationTab } from "./NavigationTab";
import { publishedStatusMap } from "./models/StatusHelper";
import { Status } from "./models/Status";
import { pluginCommentsRoute, pluginLogsRoute, pluginRoute } from "../../main/routes";

export const PluginDetailsTabs = () => {
  const { plugin } = usePlugin();
  const { pathname } = useLocation();
  const { status, publishedPluginDetails, unpublishedPluginDetails } = plugin;
  const isDetailsRoute = pathname === pluginRoute(plugin.id, Status.Published);
  const isPendingRoute = pathname === pluginRoute(plugin.id, Status.InReview);
  const isDraftRoute = pathname === pluginRoute(plugin.id, Status.InComplete);
  const isCommentsRoute = pathname === pluginCommentsRoute(plugin.id);
  const isLogsRoute = pathname === pluginLogsRoute(plugin.id);
  const showPublished = publishedStatusMap.get(status) || publishedStatusMap.get(publishedPluginDetails?.status);
  const showPending = status === Status.InReview || unpublishedPluginDetails?.status === Status.InReview;
  const showDraft = status === Status.InComplete || unpublishedPluginDetails?.status === Status.InComplete;

  return (
    <ul className="nav nav-tabs position-relative mb-3">
      {showPublished && (
        <NavigationTab isSelected={isDetailsRoute} name="Details" route={pluginRoute(plugin.id, Status.Published)} />
      )}
      {showPending && (
        <NavigationTab isSelected={isPendingRoute} name="Pending" route={pluginRoute(plugin.id, Status.InReview)} />
      )}
      {showDraft && (
        <NavigationTab isSelected={isDraftRoute} name="Draft" route={pluginRoute(plugin.id, Status.InComplete)} />
      )}
      <NavigationTab isSelected={isCommentsRoute} name="Comments" route={pluginCommentsRoute(plugin.id)} />
      <NavigationTab isSelected={isLogsRoute} name="Logs" route={pluginLogsRoute(plugin.id)} />
    </ul>
  );
};
