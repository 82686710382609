import { useAuth0 } from "@auth0/auth0-react";
import { useFormikContext } from "formik";
import { Button } from "react-bootstrap";
import { useTheme } from "../../contexts/ThemeContext";
import { Loader } from "../common/progress/Loader";
import { ExtendedUser } from "../identity/models/ExtendedUser";
import { usePluginActions } from "./hooks/usePluginActions";
import { PluginDetailsBase } from "./models/PluginDetailsBase";
import { draftPluginValidationSchema } from "./schemas/DraftPluginValidationSchema";
import { pluginValidationSchema } from "./schemas/PluginValidationSchema";
import { useLocalization } from "../../contexts/LocalizationContext";
import { Action } from "./models/Action";

export const DraftPluginButtonGroup = () => {
  const formikContext = useFormikContext<PluginDetailsBase>();
  const { values, setTouched, handleSubmit } = formikContext;
  const { isLoading, handleSubmit: submit } = usePluginActions();
  const { user } = useAuth0<ExtendedUser>();
  const { buttonStyle } = useTheme();
  const isSystemAdmin = user?.user_metadata?.isSystemAdmin;
  const { translate } = useLocalization();

  const handleSaveAsDraft = async () => {
    try {
      await draftPluginValidationSchema(translate).validate(values, {
        abortEarly: false
      });
      setTouched({});
      submit(values, Action.SaveAsDraft);
    } catch (errors) {
      setTouched({ name: true, parentProductDetailsId: true });
    }
  };

  const handleSave = async () => {
    try {
      await pluginValidationSchema(translate).validate(values, {
        abortEarly: false
      });
      setTouched({});
      if (isSystemAdmin) await submit(values, Action.Save);
      else await submit(values, Action.Submit);
    } catch (errors) {
      handleSubmit();
    }
  };

  if (isLoading) return <Loader />;

  return (
    <>
      <Button style={buttonStyle} type="button" onClick={handleSave}>
        {values.publishToRWSAppStore
          ? isSystemAdmin
            ? translate("plugins.publish")
            : translate("plugins.submit")
          : translate("common.save")}
      </Button>
      <Button variant="outline-secondary" type="button" className="mx-2" onClick={handleSaveAsDraft}>
        {translate("plugins.saveAsDraft")}
      </Button>
    </>
  );
};
