import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFeedback } from "../../../contexts/FeedbackContext";
import { useFetch } from "../../../hooks/useFetch";
import { usePlugin } from "../contexts/PluginContext";
import { DeleteInstructions } from "../models/DeleteInstructions";
import { PluginDetailsBase } from "../models/PluginDetailsBase";
import { removeHTMLTags, toFormData } from "../../../utils";
import { pluginRoute } from "../../../main/routes";
import { Status } from "../models/Status";
import { pluginDeleteRoute, pluginRequestRoute, pluginStatusRoute, savePluginRoute } from "../../../apiRoutes";
import { DeleteAction } from "../models/DeleteAction";
import { Action } from "../models/Action";
import { usePlugins } from "../contexts/PluginsContext";
import { useLocalization } from "../../../contexts/LocalizationContext";

export const usePluginActions = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { pushNotification, showModal } = useFeedback();
  const { request } = useFetch();
  const { getPlugin } = usePlugin();
  const { getPlugins } = usePlugins();
  const { pluginId } = useParams();
  const navigate = useNavigate();
  const baseUrl = process.env.PUBLIC_URL;
  const { translate } = useLocalization();

  const changeStatus = async (status: Status, id: number) => {
    const onSuccess = async () => {
      await getPlugin();
      pushNotification({
        message: translate("plugins.pluginWasSavedSuccessfully"),
        type: "success"
      });
    };

    const onFailure = () =>
      pushNotification({
        message: translate("plugins.failedToSavePlugin"),
        type: "danger"
      });

    const handleContinue = async () => {
      showModal(null);
      setIsLoading(true);
      await request({
        route: pluginStatusRoute(id, status),
        method: "PATCH",
        onSuccess,
        onFailure,
        onAny: () => setIsLoading(false)
      });
    };

    showModal({
      isVisible: true,
      message: translate("plugins.pluginVersionsDeactivated"),
      handleClose: () => showModal(null),
      handleContinue
    });
  };

  const handleRequest = async (values: PluginDetailsBase, status: Status, rejectionReason: string) => {
    const formData = toFormData(values);
    formData.set("rejectionReason", `${rejectionReason}`);

    setIsLoading(true);

    const onSuccess = async (response: Response) => {
      const json = await response.json();
      const id = json.publishedPluginDetailsId ?? json.id;
      await getPlugin(id);
      navigate(pluginRoute(id, json.status));
      pushNotification({
        message: translate("plugins.pluginStatus"),
        type: "success"
      });
    };

    const onFailure = async (response: Response) => {
      const json = await response.json();
      pushNotification({ ...json, type: "danger" });
    };

    await request({
      route: pluginRequestRoute(status),
      method: "PATCH",
      body: formData,
      onSuccess,
      onFailure,
      onAny: () => setIsLoading(false)
    });
  };

  const handleDelete = async (
    id: number,
    instructions: DeleteInstructions,
    action: DeleteAction,
    isPatch?: boolean
  ) => {
    const formData = new FormData();
    formData.set("deleteInstructions", `${instructions}`);

    const onSuccess = async (response: Response) => {
      if (pluginId && response.status === 200) {
        const json = await response.json();
        navigate(pluginRoute(pluginId, json.status));
        await getPlugin();
      } else {
        navigate(`${baseUrl}/`);
        await getPlugins();
      }

      pushNotification({
        message: translate(`plugins.deleteActions.${action}`),
        type: "success"
      });
    };

    const handleContinue = async () => {
      showModal(null);
      setIsLoading(true);
      await request({
        route: pluginDeleteRoute(id, action),
        method: isPatch ? "PATCH" : "DELETE",
        body: formData,
        onSuccess,
        onFailure: async (response) => pushNotification({ message: await response.text(), type: "danger" }),
        onAny: () => setIsLoading(false)
      });
    };

    if (action !== DeleteAction.RejectDelete) {
      showModal({
        isVisible: true,
        message: translate("plugins.removePlugin"),
        handleClose: () => showModal(null),
        handleContinue
      });
    } else handleContinue();
  };

  const handleSubmit = async (value: PluginDetailsBase, action: Action) => {
    setIsLoading(true);

    const onSuccess = async (response: Response) => {
      const json = await response.json();
      const id = json.publishedPluginDetailsId ?? json.id;
      await getPlugin(id);
      navigate(pluginRoute(id, json.status));
      pushNotification({
        message: translate("plugins.savedSuccessfully"),
        type: "success"
      });
    };

    if (!removeHTMLTags(value.description)) value.description = "";

    await request({
      route: savePluginRoute(action),
      body: toFormData(value),
      method: "POST",
      onSuccess,
      onFailure: async (response) => {
        const json = await response.json();
        pushNotification({ ...json, type: "danger" });
      },
      onAny: () => setIsLoading(false)
    });
  };

  return {
    isLoading,
    handleSubmit,
    changeStatus,
    handleDelete,
    handleRequest
  };
};
