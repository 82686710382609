import { useState } from "react";
import { useFeedback } from "../../../contexts/FeedbackContext";
import { useFetch } from "../../../hooks/useFetch";
import { useProducts } from "../contexts/ProductsContext";
import { ProductDetails } from "../models/ProductDetails";
import { toFormData } from "../../../utils";
import { useLocalization } from "../../../contexts/LocalizationContext";
import { deleteProductRoute, updateProductRoute } from "../../../apiRoutes";

export const useProductsActions = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { request } = useFetch();
  const { onEditModeIdChange, getProducts } = useProducts();
  const { pushNotification, showModal } = useFeedback();
  const { translate } = useLocalization();

  const handleSubmit = async (value: ProductDetails) => {
    setIsLoading(true);
    await request({
      route: updateProductRoute(),
      body: toFormData(value),
      method: "POST",
      onSuccess: async () => {
        onEditModeIdChange(-1);
        await getProducts();
        pushNotification({ message: translate("request.products.updateSuccess"), type: "success" });
      },
      onFailure: () => pushNotification({ message: translate("request.products.updateFail"), type: "danger" }),
      onAny: () => setIsLoading(false)
    });
  };

  const handleDelete = (id: number) => {
    const handleContinue = async () => {
      showModal(null);
      setIsLoading(true);
      await request({
        route: deleteProductRoute(id),
        method: "DELETE",
        onSuccess: async () => {
          await getProducts();
          pushNotification({ message: translate("request.products.deleteSuccess"), type: "success" });
        },
        onFailure: () => pushNotification({ message: translate("request.products.deleteFail"), type: "danger" }),
        onAny: () => setIsLoading(false)
      });
    };

    showModal({
      isVisible: true,
      message: translate("plugins.removeThisProduct"),
      handleClose: () => showModal(null),
      handleContinue
    });
  };

  return {
    isLoading,
    handleSubmit,
    handleDelete
  };
};
