import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbTack } from "@fortawesome/free-solid-svg-icons";
import { useTheme } from "../../contexts/ThemeContext";
import { Account } from "../identity/models/Account";
import { useState } from "react";
import { UserAccount } from "../identity/models/UserAccount";
import { PopupList } from "./PopupList";
import { useLocalization } from "../../contexts/LocalizationContext";
import { dateFormat } from "../../utils";

type AccountsTableRowProps = {
  account: Account;
  onPin: (value: UserAccount) => void;
  onAccountSelect: (value: UserAccount) => void;
};

export const AccountsTableRow = ({ account, onPin, onAccountSelect }: AccountsTableRowProps) => {
  const [isMouseOver, setIsMouseOver] = useState(false);
  const { theme } = useTheme();
  const { translate } = useLocalization();

  const style = isMouseOver ? { backgroundColor: `${theme.backgroundColor}99` } : {};

  const pluginNames = account.pluginDetails.map((x) => x.name);
  const emails = account.userProfiles.map((x) => {
    return x.email === x.name ? x.email : `${x.email} - ${x.name}`;
  });

  const handlePin = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    onPin({ selectedAccountId: account.id, isFavourite: !account.isFavourite });
  };

  return (
    <tr
      className="cursor-pointer text-center align-middle"
      onMouseEnter={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
      onClick={() => onAccountSelect({ selectedAccountId: account.id })}
    >
      <td style={style}>{account.name}</td>
      <td className="d-none d-xl-table-cell" style={style}>
        {account.alias}
      </td>
      <td className="d-none d-xl-table-cell" style={style}>
        <PopupList items={pluginNames} />
      </td>
      <td className="d-none d-xl-table-cell" style={style}>
        <PopupList items={emails} />
      </td>
      <td style={style} className="text-nowrap d-none d-xl-table-cell">
        {dateFormat(translate, account.createdAt)}
      </td>
      <td style={style} className="text-nowrap d-none d-xl-table-cell">
        {dateFormat(translate, account.recentAction)}
      </td>
      <td style={style}>
        <OverlayTrigger
          overlay={<Tooltip>{account.isFavourite ? translate("account.unpin") : translate("account.pin")}</Tooltip>}
        >
          <Button
            variant="transparent"
            className="p-0 border-0"
            aria-label={translate("account.pinButton")}
            onClick={handlePin}
          >
            <FontAwesomeIcon icon={faThumbTack} style={account.isFavourite ? { color: theme.backgroundColor } : {}} />
          </Button>
        </OverlayTrigger>
      </td>
    </tr>
  );
};
