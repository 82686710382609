import { useAuth0 } from "@auth0/auth0-react";
import { useFormikContext } from "formik";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { Loader } from "../common/progress/Loader";
import { ExtendedUser } from "../identity/models/ExtendedUser";
import { useVersionActions } from "./hooks/useVersionActions";
import { PluginVersionBase } from "./models/PluginVersionBase";
import { RejectionReasonModal } from "./RejectionReasonModal";
import { versionValidationSchema } from "./schemas/VersionValidationSchema";
import { useTheme } from "../../contexts/ThemeContext";
import { useLocalization } from "../../contexts/LocalizationContext";
import { Action } from "./models/Action";
import { Status } from "./models/Status";

export const PendingVersionButtonGroup = () => {
  const formikContext = useFormikContext<PluginVersionBase>();
  const { values, initialValues, setTouched, handleSubmit } = formikContext;
  const versionContext = useVersionActions(initialValues);
  const [showModal, setShowModal] = useState(false);
  const { user } = useAuth0<ExtendedUser>();
  const { isLoading, handleRequest, handleSubmit: submit } = versionContext;
  const { buttonStyle } = useTheme();
  const isSystemAdmin = user?.user_metadata?.isSystemAdmin;
  const { translate } = useLocalization();

  const handleSave = async () => {
    try {
      await versionValidationSchema(translate).validate(values, {
        abortEarly: false
      });
      setTouched({});
      if (isSystemAdmin) await submit(values, Action.Save);
      else await submit(values, Action.Submit);
    } catch (errors) {
      handleSubmit();
    }
  };

  if (isLoading) return <Loader />;

  if (isSystemAdmin) {
    return (
      <>
        <Button style={buttonStyle} type="button" onClick={() => handleRequest(values, Status.Published, "")}>
          {translate("plugins.approve")}
        </Button>
        <Button variant="danger" className="mx-2" type="button" onClick={() => setShowModal(true)}>
          {translate("plugins.reject")}
        </Button>
        {showModal && (
          <RejectionReasonModal
            onContinue={(reason) => handleRequest(values, Status.InComplete, reason)}
            onClose={() => setShowModal(false)}
          />
        )}
      </>
    );
  }

  return (
    <Button style={buttonStyle} className="me-2" type="button" onClick={handleSave}>
      {values.publishToRWSAppStore ? translate("plugins.submit") : translate("common.save")}
    </Button>
  );
};
