import { Button, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { Loader } from "../common/progress/Loader";
import { useAccounts } from "./hooks/useAccounts";
import { Account } from "./models/Account";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAnglesDown } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { useResize } from "../../hooks/useResize";
import { useLocalization } from "../../contexts/LocalizationContext";

const defaultCapacity = 10;

export const Accounts = () => {
  const [capacity, setCapacity] = useState(defaultCapacity);
  const { isLoading, data } = useAccounts<Account[]>(false);
  const { translate } = useLocalization();

  if (isLoading) return <Loader />;

  const accounts = data.slice(0, capacity);

  return (
    <>
      <hr className="d-block d-xl-none" aria-hidden />
      <h3 className="mb-3">{translate("profile.accounts")}</h3>
      <Table striped bordered>
        <thead>
          <tr className="d-none d-xl-table-row">
            <th></th>
            <th className="border">{translate("account.accountName")}</th>
            <th className="border">{translate("account.developerAlias")}</th>
            <th className="border">{translate("account.assignedRole")}</th>
          </tr>
        </thead>
        <tbody>
          {accounts?.map((account, i) => (
            <CollapsibleTableRow key={i} index={i + 1} account={account} />
          ))}
          {data.length > defaultCapacity && (
            <>
              <tr>
                <td colSpan={4} className="text-center">
                  <OverlayTrigger
                    overlay={<Tooltip>{translate("common.expand", { capacity: `${defaultCapacity}` })}</Tooltip>}
                  >
                    <Button
                      onClick={() => setCapacity((prev) => prev + defaultCapacity)}
                      variant="transparent"
                      className="p-0"
                      aria-label={translate("common.expand", { capacity: `${defaultCapacity}` })}
                    >
                      <FontAwesomeIcon icon={faAngleDown} />
                    </Button>
                  </OverlayTrigger>
                </td>
              </tr>
              <tr>
                <td colSpan={4} className="text-center">
                  <OverlayTrigger overlay={<Tooltip>{translate("common.expandAll")}</Tooltip>}>
                    <Button
                      onClick={() => setCapacity(data.length)}
                      variant="transparent"
                      className="p-0"
                      aria-label={translate("common.expandAll")}
                    >
                      <FontAwesomeIcon icon={faAnglesDown} />
                    </Button>
                  </OverlayTrigger>
                </td>
              </tr>
            </>
          )}
        </tbody>
      </Table>
    </>
  );
};

type CollapsibleTableRowProps = { account: Account; index: number };

const CollapsibleTableRow = ({ account, index }: CollapsibleTableRowProps) => {
  const { isExpanded, onToggle } = useResize();

  return (
    <tr key={account.id} className="d-flex flex-column d-xl-table-row text-center text-xl-start align-middle">
      <td className="border d-none d-xl-table-cell">{index}</td>
      <td className="border fw-bold fw-xl-normal" onClick={onToggle}>
        {account?.name}
      </td>
      {isExpanded && (
        <>
          <td className="border">{account?.alias}</td>
          <td className="border">{account?.assignedRoleName}</td>
        </>
      )}
    </tr>
  );
};
