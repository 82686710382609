import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import { useFeedback } from "../../../contexts/FeedbackContext";
import { useFetch } from "../../../hooks/useFetch";
import { ExtendedUser } from "../models/ExtendedUser";
import { UserSettings } from "../models/UserSettings";
import { toFormData } from "../../../utils";
import { userProfileSettingsRoute } from "../../../apiRoutes";
import { useLocalization } from "../../../contexts/LocalizationContext";

export const useSettings = () => {
  const { translate } = useLocalization();
  const { user } = useAuth0<ExtendedUser>();
  const { pushNotification } = useFeedback();
  const { request } = useFetch();
  const [isLoading, setIsLoading] = useState(false);
  const [settings, setSettings] = useState(user?.user_metadata as UserSettings);

  const handleSubmit = async (value: UserSettings) => {
    setIsLoading(true);
    await request({
      route: userProfileSettingsRoute(),
      body: toFormData(value),
      method: "PATCH",
      onSuccess: () => {
        setSettings(value);
        pushNotification({ message: translate("request.profiles.settingsSuccess"), type: "success" });
      },
      onFailure: () => pushNotification({ message: translate("request.profiles.settingsFail"), type: "danger" }),
      onAny: () => setIsLoading(false)
    });
  };

  return { isLoading, settings, handleSubmit };
};
