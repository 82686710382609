import { useAuth0 } from "@auth0/auth0-react";
import { Form as FormikForm, Formik } from "formik";
import { Loader } from "../components/common/progress/Loader";
import { AccountsList } from "../components/identity/AccountsList";
import { useAccounts } from "../components/identity/hooks/useAccounts";
import { Account } from "../components/identity/models/Account";
import { ExtendedUser } from "../components/identity/models/ExtendedUser";
import { UserAccount } from "../components/identity/models/UserAccount";
import { Unassigned } from "../components/identity/Unassigned";

export const Accounts = () => {
  const { data: accounts, isLoading, handleSubmit } = useAccounts<Account[]>(false);
  const { user } = useAuth0<ExtendedUser>();

  if (isLoading) return <Loader />;

  const firstAccountId = accounts?.length > 0 ? accounts[0].id : "";
  const initialValues = {
    selectedAccountId: user?.user_metadata?.selectedAccount?.id ?? firstAccountId,
    rememberAccount: false
  } as UserAccount;

  return (
    <Formik<UserAccount> onSubmit={handleSubmit} initialValues={initialValues} enableReinitialize>
      <FormikForm className="row justify-content-center">
        {accounts.length > 0 ? <AccountsList accounts={accounts} /> : <Unassigned />}
      </FormikForm>
    </Formik>
  );
};
