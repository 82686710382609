import { Loader } from "../common/progress/Loader";
import { useSettings } from "./hooks/useSettings";
import { UserProfile } from "./models/UserProfile";
import { Switch } from "../common/inputs/Switch";
import { useLocalization } from "../../contexts/LocalizationContext";

export const Settings = () => {
  const { isLoading, settings, handleSubmit } = useSettings();
  const { translate } = useLocalization();

  if (isLoading) return <Loader />;

  return (
    <>
      <hr aria-hidden className="d-block d-xl-none" />
      <h3 className="mb-3">{translate("settings.notifications")}</h3>
      <div>
        <Switch
          checked={settings?.emailNotificationsEnabled}
          name="emailNotificationsEnabled"
          label={translate("settings.enableEmailNotifications")}
          onChange={() =>
            handleSubmit({
              ...settings,
              emailNotificationsEnabled: !settings?.emailNotificationsEnabled
            } as UserProfile)
          }
        />
        <Switch
          checked={settings?.pushNotificationsEnabled}
          name="pushNotificationsEnabled"
          label={translate("settings.enablePushNotifications")}
          onChange={() =>
            handleSubmit({
              ...settings,
              pushNotificationsEnabled: !settings?.pushNotificationsEnabled
            } as UserProfile)
          }
        />
      </div>
    </>
  );
};
