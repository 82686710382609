import { Button, Spinner } from "react-bootstrap";
import { useTheme } from "../../contexts/ThemeContext";
import { useDownload } from "../../hooks/useDownload";
import { exportPluginsRoute } from "../../apiRoutes";

type ExportButtonProps = { name: string; isJson?: boolean };

export const ExportButton = ({ name, isJson }: ExportButtonProps) => {
  const { buttonStyle } = useTheme();
  const { isLoading, handleDownload } = useDownload();

  return (
    <Button
      disabled={isLoading}
      style={{ ...buttonStyle, width: 150 }}
      onClick={() => handleDownload(exportPluginsRoute(!!isJson), "{}")}
    >
      {isLoading ? <Spinner size="sm" /> : name}
    </Button>
  );
};
