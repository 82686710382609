import { useState } from "react";
import { useFeedback } from "../../../contexts/FeedbackContext";
import { useFetch } from "../../../hooks/useFetch";
import { UserInvitation } from "../../identity/models/UserInvitation";
import { toFormData } from "../../../utils";
import { inviteUserRoute, reinviteUserRoute } from "../../../apiRoutes";
import { useLocalization } from "../../../contexts/LocalizationContext";

export const useInvite = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { translate } = useLocalization();
  const { pushNotification } = useFeedback();
  const { request } = useFetch();

  const handleInvite = async (value: UserInvitation, callback: () => Promise<void>) => {
    setIsLoading(true);

    await request({
      route: inviteUserRoute(),
      body: toFormData(value),
      method: "POST",
      onSuccess: async () => {
        await callback();
        pushNotification({
          message: translate("request.account.inviteUserSuccess", { email: value.email }),
          type: "success"
        });
      },
      onFailure: () => pushNotification({ message: translate("request.account.inviteUserFail"), type: "danger" }),
      onAny: () => setIsLoading(false)
    });
  };

  const handleReinvite = async (id: string, email: string) => {
    setIsLoading(true);

    await request({
      route: reinviteUserRoute(id),
      method: "POST",
      onSuccess: () =>
        pushNotification({
          message: translate("request.account.reinviteUserSuccess", {
            email: email
          }),
          type: "success"
        }),
      onFailure: () => pushNotification({ message: translate("request.account.reinviteUserFail"), type: "danger" }),
      onAny: () => setIsLoading(false)
    });
  };

  return { isLoading, handleInvite, handleReinvite };
};
