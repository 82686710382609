import { useAuth0 } from "@auth0/auth0-react";
import { useFormikContext } from "formik";
import { Button } from "react-bootstrap";
import { useTheme } from "../../contexts/ThemeContext";
import { useVersion } from "./contexts/VersionContext";
import { Loader } from "../common/progress/Loader";
import { ExtendedUser } from "../identity/models/ExtendedUser";
import { useVersionActions } from "./hooks/useVersionActions";
import { PluginVersionBase } from "./models/PluginVersionBase";
import { Status } from "./models/Status";
import { versionValidationSchema } from "./schemas/VersionValidationSchema";
import { useState } from "react";
import { InformationMissingModal } from "./InformationMissingModal";
import { DeleteInstructions } from "./models/DeleteInstructions";
import { usePlugin } from "./contexts/PluginContext";
import { publicStatusMap, reversedStatusMap } from "./models/StatusHelper";
import { useLocalization } from "../../contexts/LocalizationContext";
import { Action } from "./models/Action";

export const PublishedVersionButtonGroup = () => {
  const formikContext = useFormikContext<PluginVersionBase>();
  const { values, initialValues, setTouched, handleSubmit } = formikContext;
  const versionContext = useVersionActions(initialValues);
  const [showModal, setShowModal] = useState(false);
  const { user } = useAuth0<ExtendedUser>();
  const { buttonStyle } = useTheme();
  const { version } = useVersion();
  const { plugin } = usePlugin();
  const { isLoading, changeStatus, handleSubmit: submit } = versionContext;
  const isSystemAdmin = user?.user_metadata?.isSystemAdmin;
  const { publishToRWSAppStore, publishToPrivateAppStore } = values;
  const isPublish = publishToRWSAppStore || publishToPrivateAppStore;
  const { translate } = useLocalization();

  const handleSave = async () => {
    try {
      await versionValidationSchema(translate).validate(values, {
        abortEarly: false
      });
      if (isPublish && plugin.hasMissingInformation) {
        setShowModal(true);
      } else {
        setTouched({});
        if (isSystemAdmin) await submit(values, Action.Save);
        else await submit(values, Action.Submit);
      }
    } catch (errors) {
      handleSubmit();
    }
  };

  const handleModalClose = () => setShowModal(false);

  if (isLoading) return <Loader />;

  return (
    <>
      {version.deleteInstructions === DeleteInstructions.None && (
        <Button style={buttonStyle} className="me-2" type="button" onClick={handleSave}>
          {values.publishToRWSAppStore && !isSystemAdmin ? translate("plugins.submit") : translate("common.save")}
        </Button>
      )}

      {publicStatusMap.get(version.status) && (
        <Button
          variant="warning"
          className="me-2"
          onClick={() => changeStatus(reversedStatusMap.get(version.status) as Status, version.versionId)}
        >
          {version.status === Status.Published ? translate("plugins.deactivate") : translate("plugins.activate")}
        </Button>
      )}
      {showModal && <InformationMissingModal onClose={handleModalClose} />}
    </>
  );
};
