import { Col, Row } from "react-bootstrap";
import { VersionDetailsForm } from "../../components/plugins/VersionDetailsForm";
import { usePlugin } from "../../components/plugins/contexts/PluginContext";
import { SideNavbar } from "../../components/plugins/SideNavbar";
import { PluginVersionTabs } from "../../components/plugins/PluginVersionTabs";
import { VersionDetailsPartial } from "../../components/plugins/VersionDetailsPartial";
import { PublishedVersionButtonGroup } from "../../components/plugins/PublishedVersionButtonGroup";
import { DeleteVersionButtonGroup } from "../../components/plugins/DeleteVersionButtonGroup";
import { DeleteInstructions } from "../../components/plugins/models/DeleteInstructions";
import { Loader } from "../../components/common/progress/Loader";
import { useVersion } from "../../components/plugins/contexts/VersionContext";
import { DownloadVersionButtonGroup } from "../../components/plugins/DownloadVersionButtonGroup";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { publicStatusMap } from "../../components/plugins/models/StatusHelper";
import { versionRoute } from "../../main/routes";
import { NotFound } from "../NotFound";

export const VersionDetails = () => {
  const { isLoading, plugin } = usePlugin();
  const { version } = useVersion();
  const navigate = useNavigate();
  const hasUrl = !!version?.downloadDetails?.downloadUrl;

  useEffect(() => {
    if (version && !publicStatusMap.get(version.status)) {
      navigate(versionRoute(plugin.id, version.versionId, plugin.status));
    }
  }, [plugin]);

  if (isLoading) return <Loader />;
  if (!version) return <NotFound />;

  return (
    <VersionDetailsForm version={version}>
      <Row>
        <Col xs={12} lg={4} style={{ borderRight: "1px solid lightgray" }}>
          <SideNavbar plugin={plugin} />
        </Col>
        <Col xs={12} lg={8}>
          <PluginVersionTabs />
          <VersionDetailsPartial hasLockedFields enableFileUpload={version.requiresSignature} />
          <div className="d-flex justify-content-between mb-2">
            <div className="d-flex">
              <PublishedVersionButtonGroup />
              <DeleteVersionButtonGroup version={version} futureInstructions={DeleteInstructions.One} />
            </div>
            {hasUrl && <DownloadVersionButtonGroup />}
          </div>
        </Col>
      </Row>
    </VersionDetailsForm>
  );
};
