import { useFormikContext } from "formik";
import { AccountSettings } from "../identity/models/AccountSettings";
import { useTheme } from "../../contexts/ThemeContext";
import { useConfig } from "../../contexts/ConfigContext";
import { useState } from "react";
import { useFetch } from "../../hooks/useFetch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { useCopy } from "../../hooks/useCopy";
import { Button, Form, InputGroup, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { useLocalization } from "../../contexts/LocalizationContext";
import { generateRepositoryGuidRoute } from "../../apiRoutes";

export const GenerateGuidInput = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { showMessage, copy } = useCopy();
  const { values, setFieldValue } = useFormikContext<AccountSettings>();
  const { theme } = useTheme();
  const { configuration } = useConfig();
  const { request } = useFetch();
  const template = configuration.privateAppStoreUrl;
  const url = template.replace("[guidid]", values.privateAppStoreGuid);
  const { translate } = useLocalization();

  const style = {
    color: theme.foregroundColor,
    backgroundColor: theme.backgroundColor,
    width: 100
  };

  const generate = async () => {
    setIsLoading(true);

    request({
      route: generateRepositoryGuidRoute(),
      method: "POST",
      onSuccess: async (response) => {
        setFieldValue("privateAppStoreGuid", await response.text());
      },
      onAny: () => setIsLoading(false)
    });
  };

  return (
    <div className="position-relative d-flex align-items-center">
      <InputGroup>
        <Form.Control
          placeholder={translate("plugins.privateAppStoreURL")}
          disabled
          value={values.privateAppStoreGuid && template.replace("[guidid]", values.privateAppStoreGuid)}
        />
        <InputGroup.Text style={style} className="cursor-pointer d-flex justify-content-center">
          <Button variant="transparent" className="p-0 text-white" onClick={generate}>
            {isLoading ? <Spinner size="sm" /> : translate("common.generate")}
          </Button>
        </InputGroup.Text>
      </InputGroup>
      {showMessage ? (
        <p style={{ position: "absolute", right: -70, margin: "0" }}>{translate("versions.copied")}</p>
      ) : (
        <OverlayTrigger overlay={<Tooltip>{translate("plugins.copyToClipboard")}</Tooltip>}>
          <Button
            className="p-0"
            variant="transparent"
            style={{ position: "absolute", right: -30 }}
            onClick={() => copy(url)}
            aria-label={translate("plugins.copyToClipboard")}
          >
            <FontAwesomeIcon icon={faCopy} />
          </Button>
        </OverlayTrigger>
      )}
    </div>
  );
};
