import { Row, Col } from "react-bootstrap";
import { Comments } from "../../components/plugins/Comments";
import { usePlugin } from "../../components/plugins/contexts/PluginContext";
import { SideNavbar } from "../../components/plugins/SideNavbar";
import { Loader } from "../../components/common/progress/Loader";
import { useVersion } from "../../components/plugins/contexts/VersionContext";
import { PluginVersionTabs } from "../../components/plugins/PluginVersionTabs";

export const VersionComments = () => {
  const { plugin, isLoading } = usePlugin();
  const { version } = useVersion();
  const comments = version?.pluginVersionComments ?? [];

  if (isLoading) return <Loader />;

  return (
    <Row>
      <Col xs={12} lg={4} style={{ borderRight: "1px solid lightgray" }}>
        <SideNavbar plugin={plugin} />
      </Col>
      <Col xs={12} lg={8}>
        <PluginVersionTabs />
        <Comments comments={comments} />
      </Col>
    </Row>
  );
};
