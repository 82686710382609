import { Col, Row } from "react-bootstrap";
import { usePlugin } from "../../components/plugins/contexts/PluginContext";
import { SideNavbar } from "../../components/plugins/SideNavbar";
import { Versions } from "../../components/plugins/Versions";
import { Loader } from "../../components/common/progress/Loader";

export const PluginVersions = () => {
  const { plugin, isLoading } = usePlugin();
  const versions = plugin?.pluginVersions ?? [];

  if (isLoading) return <Loader />;

  return (
    <Row>
      <Col xs={12} lg={4} style={{ borderRight: "1px solid lightgray" }}>
        <SideNavbar plugin={plugin} />
      </Col>
      <Col xs={12} lg={8}>
        <Versions versions={versions} />
      </Col>
    </Row>
  );
};
