import { Theme } from "./models/Theme";
import { ThemeContextProps } from "./models/ThemeContextProps";
import { useAuth0 } from "@auth0/auth0-react";
import { ExtendedUser } from "../components/identity/models/ExtendedUser";
import { useUser } from "./UserContext";
import { createContext, ReactElement, useCallback, useContext, useEffect, useState } from "react";

type ThemeProviderProps = { children: ReactElement };

export const ThemeContext = createContext<ThemeContextProps>({
  theme: {},
  buttonStyle: {},
  setField: () => undefined,
  restoreTheme: () => undefined
});

export const useTheme = () => useContext(ThemeContext);

export const ThemeProvider = ({ children }: ThemeProviderProps) => {
  const [theme, setTheme] = useState({} as Theme);
  const { user } = useAuth0<ExtendedUser>();
  const { isLoading } = useUser();

  useEffect(() => {
    if (!isLoading) {
      const account = user?.user_metadata?.selectedAccount;
      const preferences = account?.accountPreferences;

      setTheme({
        backgroundColor: preferences?.backgroundColor ?? "#28b059",
        foregroundColor: preferences?.foregroundColor ?? "#ffffff",
        fontSize: preferences?.fontSize ?? 16,
        fontFamily: preferences?.fontFamily,
        iconUrl: preferences?.iconUrl
      });
    }
  }, [user, isLoading]);

  useEffect(() => {
    const body = document.querySelector("body") as HTMLBodyElement;
    const defaultFont = "var(--bs-font-sans-serif)";

    if (body.style) {
      body.style.fontFamily = theme.fontFamily ?? defaultFont;
      body.style.fontSize = `${theme.fontSize}px`;
    }
  }, [theme]);

  const setField = useCallback(
    (name: string, value: string) => {
      setTheme({ ...theme, [name]: value });
    },
    [setTheme, theme]
  );

  const restoreTheme = useCallback(() => {
    setTheme({
      backgroundColor: "#28b059",
      foregroundColor: "#ffffff",
      fontFamily: undefined,
      fontSize: 16,
      iconUrl: ""
    });
  }, [setTheme]);

  const buttonStyle: React.CSSProperties = {
    backgroundColor: theme?.backgroundColor,
    border: `1px solid ${theme?.backgroundColor}`,
    color: theme?.foregroundColor
  };

  return (
    <ThemeContext.Provider value={{ theme, buttonStyle, setField, restoreTheme }}>{children}</ThemeContext.Provider>
  );
};
