import { useState } from "react";
import { useFeedback } from "../../../contexts/FeedbackContext";
import { useFetch } from "../../../hooks/useFetch";
import { AccountGrant } from "../../identity/models/AccountGrant";
import { toFormData } from "../../../utils";
import { registerAccountRoute } from "../../../apiRoutes";
import { useLocalization } from "../../../contexts/LocalizationContext";

export const useRegister = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { translate } = useLocalization();
  const { pushNotification } = useFeedback();
  const { request } = useFetch();

  const handleSubmit = async (value: AccountGrant) => {
    setIsLoading(true);
    await request({
      route: registerAccountRoute(),
      body: toFormData(value),
      method: "POST",
      onSuccess: () =>
        pushNotification({
          message: translate("request.account.registerSuccess", {
            accountName: value.name,
            email: value.userProfileIdentifier
          }),
          type: "success"
        }),
      onFailure: () =>
        pushNotification({
          message: translate("request.account.registerFail", {
            accountName: value.name
          }),
          type: "danger"
        }),
      onAny: () => setIsLoading(false)
    });
  };

  return { isLoading, handleSubmit };
};
