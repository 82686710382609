import { useFormikContext } from "formik";
import { PluginVersionBase } from "../models/PluginVersionBase";
import { SetStateAction, useEffect, useState } from "react";
import { useFetch } from "../../../hooks/useFetch";
import { usePlugin } from "../contexts/PluginContext";
import { getFileNameFromPath } from "../../../utils";
import { extractPluginVersionRoute } from "../../../apiRoutes";

export const useUploadFileArea = (setIsLoading: (value: boolean) => void) => {
  const formik = useFormikContext<PluginVersionBase>();
  const [dragOver, setDragOver] = useState(false);
  const [fileName, setFileName] = useState<string | undefined>();
  const { values, setFieldValue, setValues } = formik;
  const { request } = useFetch();
  const { productDetails, plugin } = usePlugin();

  useEffect(() => {
    if (!values.isNavigationLink) {
      setFileName(values?.versionUpload?.name ?? getFileNameFromPath(values?.downloadDetails?.downloadUrl ?? "") ?? "");
    }
  }, [values]);

  const handleNavigationLink = (value: boolean) => {
    if (value) {
      setFieldValue("versionUpload", undefined);
      setFieldValue("fileHash", "");
    } else setFieldValue("downloadDetails.downloadUrl", "");

    setFieldValue("isNavigationLink", value);
    setFieldValue("validateVersionUpload", !value);
  };

  const handleRemoveFile = () => {
    setFileName(undefined);
    setFieldValue("versionUpload", null);
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    if (e.target.files) await extract(e.target.files[0]);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragOver(false);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragOver(true);
  };

  const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setDragOver(false);
    await extract(e.dataTransfer.files[0]);
  };

  const extract = async (file: File) => {
    const data = new FormData();
    data.set("versionUpload", file);

    setFileName(file?.name);
    setIsLoading(true);

    const onSuccess = async (response: Response) => {
      const json = await response.json();

      setValues({
        ...values,
        versionUpload: file,
        versionNumber: json.version,
        fileHash: json.checksum,
        minimumRequiredVersionOfStudio: json.requiredProduct.minimumStudioVersion,
        maximumRequiredVersionOfStudio: json.requiredProduct.maximumStudioVersion,
        supportedProducts: productDetails
          .filter((x) => x.minimumStudioVersion === json.requiredProduct.minimumStudioVersion)
          .map((x) => x.id)
      } as SetStateAction<PluginVersionBase>);
    };

    await request({
      route: extractPluginVersionRoute(plugin.id),
      method: "POST",
      body: data,
      onSuccess,
      onAny: () => setIsLoading(false)
    });
  };

  return {
    fileName,
    dragOver,
    handleNavigationLink,
    handleRemoveFile,
    handleFileChange,
    handleDragLeave,
    handleDragOver,
    handleDrop
  };
};
