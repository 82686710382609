import { Spinner } from "react-bootstrap";
import { useState } from "react";
import { useFetch } from "../../hooks/useFetch";
import { useLocalization } from "../../contexts/LocalizationContext";
import { PushNotificationMini } from "./models/PushNotificationMini";
import { selectAccountRoute } from "../../apiRoutes";
import { toFormData } from "../../utils";

type NotificationProps = { notification: PushNotificationMini };

export const AdminNotification = ({ notification }: NotificationProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { request } = useFetch();
  const { translate } = useLocalization();

  const handleView = () => {
    setIsLoading(true);
    request({
      route: selectAccountRoute(),
      body: toFormData({ selectedAccountId: notification.accountId }),
      method: "PATCH",
      onSuccess: async () => {
        window.location.href = `${process.env.PUBLIC_URL}/`;
      }
    });
  };

  return (
    <div className="p-3">
      <span className="d-flex">
        <p className="m-0 fw-bold me-2">{notification.accountName}:</p>
        <p className="m-0">
          {translate("notification.activeNotifications", { notificationCount: `${notification.notificationsCount}` })}
        </p>
      </span>
      {isLoading ? (
        <Spinner size="sm" />
      ) : (
        <p className="m-0 text-decoration-underline text-primary cursor-pointer" onClick={handleView}>
          {translate("notification.takeAction")}
        </p>
      )}
    </div>
  );
};
