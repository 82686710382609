import { forwardRef, SelectHTMLAttributes } from "react";
import { Form } from "react-bootstrap";
import { SelectItem } from "./models/SelectItem";
import { useLocalization } from "../../../contexts/LocalizationContext";

type SelectProps = Omit<SelectHTMLAttributes<HTMLSelectElement>, "size"> & {
  items: SelectItem[];
  size?: "sm" | "lg";
};

const Component = ({ placeholder, items, ...props }: SelectProps, ref: React.Ref<HTMLSelectElement>) => {
  const { translate } = useLocalization();

  return (
    <Form.Select {...props} ref={ref}>
      <option value={""} hidden disabled>
        {placeholder ?? translate("plugins.select")}
      </option>
      <option value={"0"} hidden disabled>
        {placeholder ?? translate("plugins.select")}
      </option>
      {items?.map((option, i) => (
        <option key={i} value={option.value}>
          {option.text}
        </option>
      ))}
    </Form.Select>
  );
};

export const Select = forwardRef(Component);
