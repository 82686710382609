import { Button, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { Account } from "../identity/models/Account";
import { AccountsFilter } from "./model/AccountsFilter";
import { TableHead } from "./TableHead";
import { SearchableTableHead } from "./SearchableTableHead";
import { UserAccount } from "../identity/models/UserAccount";
import { AccountsTableRow } from "./AccountsTableRow";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAnglesDown } from "@fortawesome/free-solid-svg-icons";
import { SortableTableHead } from "./SortableTableHead";
import { useLocalization } from "../../contexts/LocalizationContext";
import { AccountsFilterActionType } from "./model/AccountsFilterActionType";

type AccountsTableProps = {
  accounts: Account[];
  filters: AccountsFilter;
  handleFiltersChange: (action: AccountsFilterActionType, value?: string) => void;
  onAccountSelect: (value: UserAccount) => void;
  onPin: (value: UserAccount) => void;
};

const defaultCapacity = 10;

export const AccountsTable = ({
  filters,
  accounts,
  onPin,
  handleFiltersChange,
  onAccountSelect
}: AccountsTableProps) => {
  const [capacity, setCapacity] = useState(defaultCapacity);
  const newAccounts = accounts.slice(0, capacity);
  const { translate } = useLocalization();

  return (
    <Table striped bordered>
      <thead>
        <tr className="d-none d-xl-table-row text-center align-middle">
          <th>
            <TableHead
              value={filters.AccountName}
              name={translate("account.accountName")}
              sortType={filters.AccountNameSort}
              setSearch={(value) => handleFiltersChange(AccountsFilterActionType.SetAccountName, value)}
              setSortType={(type) => handleFiltersChange(AccountsFilterActionType.SetAccountNameSort, type)}
            />
          </th>
          <th>
            <TableHead
              value={filters.AccountAlias}
              setSearch={(value) => handleFiltersChange(AccountsFilterActionType.SetAccountAlias, value)}
              setSortType={(type) => handleFiltersChange(AccountsFilterActionType.SetAccountAliasSort, type)}
              name={translate("account.accountAlias")}
              sortType={filters.AccountAliasSort}
            />
          </th>
          <th style={{ minWidth: 125 }}>
            <SearchableTableHead
              value={filters.AppName}
              name={translate("account.apps")}
              setSearch={(value) => handleFiltersChange(AccountsFilterActionType.SetAppName, value)}
            />
          </th>
          <th style={{ minWidth: 125 }}>
            <SearchableTableHead
              value={filters.User}
              name={translate("account.users")}
              setSearch={(value) => handleFiltersChange(AccountsFilterActionType.SetUser, value)}
            />
          </th>
          <th>
            <div className="d-flex align-items-center">
              <p className="m-0 text-nowrap">{translate("plugins.createdDate")}</p>
              <SortableTableHead
                sortType={filters.CreatedDateSort}
                setSortType={(type) => handleFiltersChange(AccountsFilterActionType.SetCreatedDateSort, type)}
              />
            </div>
          </th>
          <th>
            <div className="d-flex align-items-center">
              <p className="m-0 text-nowrap">{translate("plugins.recentAction")}</p>
              <SortableTableHead
                sortType={filters.RecentActionSort}
                setSortType={(type) => handleFiltersChange(AccountsFilterActionType.SetRecentActionSort, type)}
              />
            </div>
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {newAccounts.length ? (
          <>
            {newAccounts.map((x) => (
              <AccountsTableRow key={x.id} account={x} onAccountSelect={onAccountSelect} onPin={onPin} />
            ))}
            {accounts.length > defaultCapacity && (
              <>
                <tr>
                  <td colSpan={7} className="text-center">
                    <OverlayTrigger
                      overlay={<Tooltip>{translate("common.expand", { capacity: `${defaultCapacity}` })}</Tooltip>}
                    >
                      <Button
                        variant="transparent"
                        className="p-0"
                        aria-label={translate("common.expand", { capacity: `${defaultCapacity}` })}
                        onClick={() => setCapacity((prev) => prev + defaultCapacity)}
                      >
                        <FontAwesomeIcon icon={faAngleDown} />
                      </Button>
                    </OverlayTrigger>
                  </td>
                </tr>
                <tr>
                  <td colSpan={7} className="text-center">
                    <OverlayTrigger overlay={<Tooltip>{translate("common.expandAll")}</Tooltip>}>
                      <Button
                        variant="transparent"
                        className="p-0"
                        aria-label={translate("common.expandAll")}
                        onClick={() => setCapacity(accounts.length)}
                      >
                        <FontAwesomeIcon icon={faAnglesDown} />
                      </Button>
                    </OverlayTrigger>
                  </td>
                </tr>
              </>
            )}
          </>
        ) : (
          <tr>
            <td colSpan={7} className="text-center align-middle">
              {translate("account.noAccountsFound")}
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};
