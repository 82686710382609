import { Col, Row } from "react-bootstrap";
import { SideNavbar } from "../common/navigation/SideNavbar";
import { Outlet } from "react-router-dom";
import { MatchAlgorithm } from "../common/navigation/models/MatchAlgorithm";
import { TranslateFunction, useLocalization } from "../../contexts/LocalizationContext";
import { administrationCategoriesRoute, administrationProductsRoute } from "../../main/routes";

const links = (translate: TranslateFunction) => [
  { name: translate("plugins.categories"), route: administrationCategoriesRoute() },
  { name: translate("plugins.products"), route: administrationProductsRoute() }
];

export const Layout = () => {
  const { translate } = useLocalization();

  return (
    <>
      <h1 aria-hidden>{translate("settings.applicationSettings")}</h1>
      <h2>{translate("settings.manageApplicationSettings")}</h2>
      <hr className="w-100" aria-hidden></hr>
      <Row>
        <SideNavbar items={links(translate)} matchAlgorithm={MatchAlgorithm.Includes} />
        <Col xs={12} xl={9}>
          <div className="h-100">{<Outlet />}</div>
        </Col>
      </Row>
    </>
  );
};
