import { useAuth0 } from "@auth0/auth0-react";
import { Formik, Form as FormikForm, useFormikContext } from "formik";
import { Button, Container } from "react-bootstrap";
import { useTheme } from "../../contexts/ThemeContext";
import { Loader } from "../common/progress/Loader";
import { AccountSettings } from "../identity/models/AccountSettings";
import { ExtendedUser } from "../identity/models/ExtendedUser";
import { useSettings } from "./hooks/useSettings";
import { SettingsContainer } from "./SettingsContainer";
import { useLocalization } from "../../contexts/LocalizationContext";

export const Settings = () => {
  const { theme } = useTheme();
  const { user } = useAuth0<ExtendedUser>();
  const account = user?.user_metadata?.selectedAccount;

  const initialValues = {
    id: account?.id ?? "",
    publishToRWSAppStore: account?.publishToRWSAppStore ?? false,
    enablePrivateAppStore: account?.enablePrivateAppStore ?? false,
    publishToPrivateAppStore: account?.publishToPrivateAppStore ?? false,
    includeChangelogOnShare: account?.includeChangelogOnShare ?? false,
    privateAppStoreGuid: account?.privateAppStoreGuid ?? "",
    accountPreferences: {
      iconUrl: theme.iconUrl ?? "",
      fontFamily: theme.fontFamily ?? "",
      fontSize: theme.fontSize ?? 16,
      backgroundColor: theme.backgroundColor ?? "",
      foregroundColor: theme.foregroundColor ?? ""
    }
  };

  return (
    <Formik<AccountSettings> initialValues={initialValues} onSubmit={() => undefined}>
      <Container className="p-0">
        <AccountSettingsForm />
      </Container>
    </Formik>
  );
};

const AccountSettingsForm = () => {
  const { values } = useFormikContext<AccountSettings>();
  const { buttonStyle } = useTheme();
  const { isLoading, handleSubmit, resetSettings } = useSettings();
  const { translate } = useLocalization();

  if (isLoading) return <Loader />;

  return (
    <FormikForm>
      <SettingsContainer />
      <div className="mb-3">
        <Button type="button" variant="secondary" className="me-2" onClick={resetSettings}>
          {translate("common.reset")}
        </Button>
        <Button type="button" style={buttonStyle} onClick={() => handleSubmit(values)}>
          {translate("common.save")}
        </Button>
      </div>
    </FormikForm>
  );
};
