import { useFormikContext } from "formik";
import { Button } from "react-bootstrap";
import { Editor } from "../common/inputs/Editor";
import { Loader } from "../common/progress/Loader";
import { useComments } from "./hooks/useComments";
import { Comment } from "./models/Comment";
import { commentValidationSchema } from "./schemas/CommentValidationSchema";
import { useTheme } from "../../contexts/ThemeContext";
import { useEffect } from "react";
import { usePreserve } from "../../contexts/PreserveContext";
import { useLocalization } from "../../contexts/LocalizationContext";

type EditCommentProps = {
  handleDiscard: () => void;
  comments: Comment[];
};

export const EditComment = ({ handleDiscard, comments }: EditCommentProps) => {
  const formik = useFormikContext<Comment>();
  const { buttonStyle } = useTheme();
  const { isLoading, handleSubmit: submit } = useComments(comments);
  const { errors, touched, values, initialValues } = formik;
  const { setTouched, handleSubmit } = formik;
  const { setCurrentState, setInitialState, reset } = usePreserve();
  const { translate } = useLocalization();

  useEffect(() => {
    setCurrentState(values);
  }, [values]);

  useEffect(() => {
    setInitialState(initialValues);
  }, [initialValues]);

  const handleSave = async () => {
    try {
      await commentValidationSchema(translate).validate(values, {
        abortEarly: false
      });
      setTouched({});
      submit(values);
      reset();
    } catch (errors) {
      handleSubmit();
    }
  };

  if (isLoading) return <Loader />;

  return (
    <div className="mb-2">
      <Editor name="description" value={values.description} />
      {touched.description && !!errors.description && (
        <span className="invalid-feedback d-inline">{errors.description}</span>
      )}
      <div className="mt-2">
        <Button type="button" style={buttonStyle} className="me-2" onClick={handleSave}>
          {translate("common.save")}
        </Button>
        <Button type="button" variant="outline-secondary" onClick={handleDiscard}>
          {translate("common.discard")}
        </Button>
      </div>
    </div>
  );
};
