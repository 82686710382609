import { Row, Col } from "react-bootstrap";
import { Comments } from "../../components/plugins/Comments";
import { usePlugin } from "../../components/plugins/contexts/PluginContext";
import { PluginDetailsTabs } from "../../components/plugins/PluginDetailsTabs";
import { SideNavbar } from "../../components/plugins/SideNavbar";
import { Loader } from "../../components/common/progress/Loader";

export const PluginComments = () => {
  const { plugin, isLoading } = usePlugin();
  const comments = plugin?.pluginDetailsComments ?? [];

  if (isLoading) return <Loader />;

  return (
    <Row>
      <Col xs={12} lg={4} style={{ borderRight: "1px solid lightgray" }}>
        <SideNavbar plugin={plugin} />
      </Col>
      <Col xs={12} lg={8}>
        <PluginDetailsTabs />
        <Comments comments={comments} />
      </Col>
    </Row>
  );
};
