import * as Yup from "yup";
import { TranslateFunction } from "../../../contexts/LocalizationContext";

export const registerValidationSchema = (translate : TranslateFunction) => Yup.object().shape({
  userProfileIdentifier: Yup.string()
    .email(translate("validation.invalidEmail"))
    .required(translate("validation.fieldRequired")),
  name: Yup.string().required(translate("validation.fieldRequired")),
  alias: Yup.string()
});
