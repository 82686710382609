import { TranslateFunction } from "./contexts/LocalizationContext";

export const getFileNameFromPath = (path: string) => {
  if (path === "") {
    return;
  }

  let fileName = path.split("/").pop() ?? "";
  const queryIndex = fileName.indexOf("?");

  fileName = queryIndex > 0 ? fileName.substring(0, queryIndex) : fileName;

  return decodeURIComponent(fileName);
};

export const toFormData = <T extends object>(data: T): FormData => {
  const formData = new FormData();

  for (const [key, value] of Object.entries(data)) {
    toFormDataPair(key, value, formData);
  }

  return formData;
};

const toFormDataPair = (key: string, value: any, formData: FormData) => {
  if (!value) {
    return;
  }

  if (value instanceof File) {
    formData.append(key, value);
  } else if (Array.isArray(value)) {
    value.forEach((x) => toFormDataPair(`${key}[]`, x, formData));
  } else if (typeof value === "object") {
    for (const tuple of Object.entries(value)) {
      toFormDataPair(`${key}.${tuple[0]}`, tuple[1], formData);
    }
  } else {
    formData.append(key, `${value}`);
  }
};

export const removeHTMLTags = (input: any) => {
  const pattern = /<.*?>/gm;
  return input?.replace(pattern, "");
};

export const stringFormat = (string: string, ...args: any) => {
  return string.replace(/{(\d+)}/g, (match, index) => {
    return typeof args[index] !== "undefined" ? args[index] : match;
  });
};

export const dateFormat = (translate: TranslateFunction, date?: Date) => {
  return date ? new Date(date).toISOString().slice(0, 10) : translate("common.unknown");
};

export const sequencesEqual = <T>(first: T[], second: T[]) => {
  const firstSet = new Set(first);
  const secondSet = new Set(second);

  return !Array.from(firstSet.values()).some((x) => !secondSet.has(x));
};
