import { useEffect, useState } from "react";
import { usePreserve } from "../../../contexts/PreserveContext";
import { useFormikContext } from "formik";
import { PluginDetailsBase } from "../models/PluginDetailsBase";
import { DisabledFields } from "../models/DisabledFields";
import { useFeedback } from "../../../contexts/FeedbackContext";
import { usePlugin } from "../contexts/PluginContext";
import { useLocalization } from "../../../contexts/LocalizationContext";

export const usePluginDetailsPartial = (hasLockedFields: boolean) => {
  const formikContext = useFormikContext<PluginDetailsBase>();
  const { setCurrentState, setInitialState } = usePreserve();
  const { values, initialValues, setFieldValue } = formikContext;
  const [disabledFields, setDisabledFields] = useState<DisabledFields>({});
  const { plugin } = usePlugin();
  const { translate } = useLocalization();

  const { showModal } = useFeedback();

  useEffect(() => {
    setCurrentState(values);
  }, [values]);

  useEffect(() => {
    setInitialState(initialValues);
  }, [initialValues]);

  useEffect(() => {
    const fields = new DisabledFields(hasLockedFields);

    fields.sharedParentProductIds = hasLockedFields || values.parentProductDetailsId === 0;

    setDisabledFields(fields);
  }, [hasLockedFields, values.parentProductDetailsId]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) setFieldValue("iconUpload", e.target.files[0]);
  };

  const handleFieldDisable = (field: keyof DisabledFields) => {
    setDisabledFields((prev) => ({ ...prev, [field]: !prev[field] }));
  };

  const handleStatusChange = ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
    const handleContinue = () => {
      setFieldValue("publishToRWSAppStore", !values.publishToRWSAppStore);
      showModal(null);
    };

    const { unpublishedPluginDetails, publishToRWSAppStore } = plugin;

    if (!unpublishedPluginDetails && publishToRWSAppStore && !checked) {
      showModal({
        isVisible: true,
        message: translate("versions.versionsPrivate"),
        handleClose: () => showModal(null),
        handleContinue
      });
    } else setFieldValue("publishToRWSAppStore", !values.publishToRWSAppStore);
  };

  return {
    disabledFields,
    handleFileChange,
    handleFieldDisable,
    handleStatusChange
  };
};
