import { useState } from "react";
import { useFeedback } from "../../../contexts/FeedbackContext";
import { Comment } from "../models/Comment";
import { useFetch } from "../../../hooks/useFetch";
import { usePlugin } from "../contexts/PluginContext";
import { removeHTMLTags, toFormData } from "../../../utils";
import { useLocalization } from "../../../contexts/LocalizationContext";
import { deleteCommentRoute, updateCommentRoute } from "../../../apiRoutes";

export const useComments = (comments: Comment[]) => {
  const [editModeId, setEditModeId] = useState(-1);
  const [isLoading, handleLoadingChange] = useState(false);
  const { getPlugin: getPlugins } = usePlugin();
  const { pushNotification, showModal } = useFeedback();
  const { request } = useFetch();
  const { translate } = useLocalization();

  const handleSubmit = async (value: Comment) => {
    const initial = comments?.find((x) => x.id === value.id);

    if (value?.description === initial?.description) {
      setEditModeId(-1);
    } else {
      handleLoadingChange(true);

      if (!removeHTMLTags(value.description)) value.description = "";

      await request({
        route: updateCommentRoute(),
        body: toFormData(value),
        method: "POST",
        onSuccess: async () => {
          await getPlugins();
          pushNotification({
            message: translate("plugins.commentSavedSuccessfully"),
            type: "success"
          });
        },
        onFailure: () =>
          pushNotification({
            message: translate("plugins.failedToSaveComment"),
            type: "danger"
          }),
        onAny: () => {
          handleLoadingChange(false);
          setEditModeId(-1);
        }
      });
    }
  };

  const handleDelete = (id: number) => {
    const handleContinue = async () => {
      showModal(null);
      handleLoadingChange(true);
      await request({
        route: deleteCommentRoute(id),
        method: "DELETE",
        onSuccess: async () => {
          await getPlugins();
          pushNotification({
            message: translate("plugins.commentDeletedSuccessfully"),
            type: "success"
          });
        },
        onFailure: () =>
          pushNotification({
            message: translate("plugins.failedToDeleteComment"),
            type: "danger"
          }),
        onAny: () => handleLoadingChange(false)
      });
    };

    showModal({
      isVisible: true,
      message: translate("plugins.removeThisComment"),
      handleClose: () => showModal(null),
      handleContinue
    });
  };

  return { editModeId, isLoading, handleDelete, handleSubmit };
};
