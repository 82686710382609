import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useEffect, useState } from "react";
import { useFeedback } from "../../../contexts/FeedbackContext";
import { useUser } from "../../../contexts/UserContext";
import { useFetch } from "../../../hooks/useFetch";
import { AccountBase } from "../../identity/models/AccountBase";
import { ExtendedUser } from "../../identity/models/ExtendedUser";
import { toFormData } from "../../../utils";
import { oneAccountRoute, updateAccountRoute } from "../../../apiRoutes";
import { useLocalization } from "../../../contexts/LocalizationContext";

export const useAccount = (fetch: boolean) => {
  const [account, setAccount] = useState<AccountBase>();
  const [isLoading, setIsLoading] = useState(fetch);
  const { translate } = useLocalization();
  const { user } = useAuth0<ExtendedUser>();
  const { pushNotification } = useFeedback();
  const { getUser } = useUser();
  const { request } = useFetch();
  const isGranted = user?.user_metadata?.isAdmin;

  const getAccount = useCallback(async () => {
    await request({
      route: oneAccountRoute(),
      onSuccess: async (response) => {
        setAccount(await response.json());
      },
      onAny: () => setIsLoading(false)
    });
  }, [request]);

  useEffect(() => {
    if (fetch) getAccount();
  }, [fetch, getAccount]);

  const handleSubmit = async (value: AccountBase) => {
    if (isGranted) {
      setIsLoading(true);

      await request({
        route: updateAccountRoute(),
        body: toFormData(value),
        method: "POST",
        onSuccess: async () => {
          await getUser();
          pushNotification({ message: translate("request.account.updateSuccess"), type: "success" });
        },
        onFailure: () => pushNotification({ message: translate("request.account.updateFail"), type: "danger" }),
        onAny: () => setIsLoading(false)
      });
    }
  };

  return { isLoading, account, isGranted, handleSubmit };
};
