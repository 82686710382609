import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useTheme } from "../../contexts/ThemeContext";
import { Select } from "../common/inputs/Select";
import { SelectItem } from "../common/inputs/models/SelectItem";
import { ProductDetails } from "../applicationsettings/models/ProductDetails";
import { faMagnifyingGlass, faPuzzlePiece } from "@fortawesome/free-solid-svg-icons";
import { TranslateFunction, useLocalization } from "../../contexts/LocalizationContext";
import { newPluginRoute } from "../../main/routes";
import { PluginsFilters } from "./models/PluginsFilters";
import { PluginsFilterActionType } from "./models/PluginsFilterActionType";

const statusFilters = (translate: TranslateFunction): SelectItem[] => [
  { value: "Published", text: translate("plugins.published") },
  { value: "NotPublished", text: translate("plugins.notPublished") },
  { value: "InComplete", text: translate("plugins.inComplete") },
  { value: "InReview", text: translate("plugins.inReview") },
  { value: "Private", text: translate("plugins.private") },
  { value: "", text: translate("plugins.all") }
];

type PluginsToolbarProps = {
  products: ProductDetails[];
  filters: PluginsFilters;
  handleFiltersChange: (action: PluginsFilterActionType, value: any) => void;
};

export const PluginsToolbar = ({ filters, products, handleFiltersChange }: PluginsToolbarProps) => {
  const { buttonStyle, theme } = useTheme();
  const { translate } = useLocalization();

  const selectStyle: React.CSSProperties = {
    backgroundColor: theme?.backgroundColor,
    color: theme?.foregroundColor,
    borderRadius: "3px",
    border: "none",
    padding: "6px 10px",
    height: "100%"
  };

  return (
    <Container fluid>
      <Row>
        <h2 className="p-0 screen-reader-element">{translate("plugins.pluginsToolbar")}</h2>
        <Col xs={12} xl={8} className="m-0 order-1 order-xl-0 p-0">
          <Row className="flex-column flex-xl-row">
            <Col xs={12} xl={8} className="d-flex justify-content-between my-3 m-xl-0">
              <div className="d-flex align-items-center me-2">
                <p className="m-0 text-nowrap">{translate("plugins.quickFilters")}</p>
              </div>
              <div style={{ width: "35%" }}>
                <Select
                  id="status"
                  value={filters.Status}
                  onChange={(e) => handleFiltersChange(PluginsFilterActionType.SetStatus, e.target.value)}
                  items={statusFilters(translate)}
                  placeholder={translate("plugins.statusSelect")}
                  style={selectStyle}
                  aria-label={translate("account.status")}
                />
              </div>
              <div style={{ width: "35%" }}>
                <Select
                  value={filters.Product}
                  id="products"
                  onChange={(e) => handleFiltersChange(PluginsFilterActionType.SetProduct, e.target.value)}
                  placeholder={translate("plugins.products")}
                  style={selectStyle}
                  aria-label={translate("plugins.productsSelect")}
                  items={products.map((x) => ({ value: x.productName, text: x.productName }))}
                />
              </div>
            </Col>
            <Col xs={12} xl={4}>
              <div className="input-group h-100">
                <Form.Control
                  size="sm"
                  type="text"
                  id="query"
                  value={filters.Query}
                  autoComplete="off"
                  placeholder={translate("plugins.searchPlugin")}
                  aria-label={translate("plugins.searchPlugin")}
                  onChange={(e) => handleFiltersChange(PluginsFilterActionType.SetQuery, e.target.value)}
                />
                <span className="input-group-text px-3 bg-light">
                  <Button className="border-0 bg-light p-0" aria-label={translate("plugins.searchButton")}>
                    <FontAwesomeIcon color="black" icon={faMagnifyingGlass} />
                  </Button>
                </span>
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs={12} xl={4} className="d-flex justify-content-start justify-content-xl-end order-0 order-xl-1 p-0">
          <a href={newPluginRoute()} className="btn w-100 w-xl-fit" style={buttonStyle}>
            <FontAwesomeIcon icon={faPuzzlePiece} className="me-2" />
            {translate("plugins.newApp")}
          </a>
        </Col>
      </Row>
    </Container>
  );
};
