import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFeedback } from "../../../contexts/FeedbackContext";
import { useUser } from "../../../contexts/UserContext";
import { useFetch } from "../../../hooks/useFetch";
import { accountAgreementRoute } from "../../../apiRoutes";
import { useLocalization } from "../../../contexts/LocalizationContext";

export const useAgreement = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { translate } = useLocalization();
  const { getUser } = useUser();
  const { pushNotification } = useFeedback();
  const { request } = useFetch();
  const navigate = useNavigate();

  const handleSubmit = async (isAgreementAccepted: boolean) => {
    const formData = new FormData();
    formData.set("isAgreementAccepted", `${isAgreementAccepted}`);

    setIsLoading(true);
    await request({
      route: accountAgreementRoute(),
      body: formData,
      method: "POST",
      onSuccess: async () => {
        await getUser();
        navigate("/");
      },
      onFailure: () => pushNotification({ message: translate("request.authentication.agreementFail"), type: "danger" }),
      onAny: () => setIsLoading(false)
    });
  };

  return { isLoading, handleSubmit };
};
