import { Button, Spinner } from "react-bootstrap";
import { useTheme } from "../../contexts/ThemeContext";
import { Account } from "../identity/models/Account";
import { useDownload } from "../../hooks/useDownload";
import { useLocalization } from "../../contexts/LocalizationContext";
import { exportAccountsRoute } from "../../apiRoutes";

type ExportButtonProps = { accounts: Account[] };

export const ExportButton = ({ accounts }: ExportButtonProps) => {
  const { buttonStyle } = useTheme();
  const { isLoading, handleDownload } = useDownload();
  const data = JSON.stringify(accounts);
  const { translate } = useLocalization();

  const onExport = () => handleDownload(exportAccountsRoute(), data, "application/json");

  return (
    <Button disabled={isLoading} onClick={onExport} style={{ ...buttonStyle, width: 150 }}>
      {isLoading ? <Spinner size="sm" /> : translate("settings.exportToExcel")}
    </Button>
  );
};
