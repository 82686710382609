import { Status } from "./Status";

export enum Action {
  Save = "save",
  Submit = "submit",
  SaveAsDraft = "draft"
}

export const actionStatusMap = new Map<Action, Status>([
  [Action.SaveAsDraft, Status.InComplete]
]);
