import { useState } from "react";
import { useFeedback } from "../../../contexts/FeedbackContext";
import { useFetch } from "../../../hooks/useFetch";
import { ParentProductDetails } from "../models/ParentProductDetails";
import { useParentProducts } from "../contexts/ParentProductsContext";
import { toFormData } from "../../../utils";
import { useLocalization } from "../../../contexts/LocalizationContext";
import { deleteParentProductRoute, updateParentProductRoute } from "../../../apiRoutes";

export const useParentProductsActions = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { request } = useFetch();
  const { pushNotification, showModal } = useFeedback();
  const { getParentProducts, onEditModeIdChange } = useParentProducts();
  const { translate } = useLocalization();

  const handleDelete = (id: number) => {
    const handleContinue = async () => {
      showModal(null);
      setIsLoading(true);
      await request({
        route: deleteParentProductRoute(id),
        method: "DELETE",
        onSuccess: async () => {
          await getParentProducts();
          pushNotification({ message: translate("request.parentProducts.deleteSuccess"), type: "success" });
        },
        onFailure: () => pushNotification({ message: translate("request.parentProducts.deleteFail"), type: "danger" }),
        onAny: () => setIsLoading(false)
      });
    };

    showModal({
      isVisible: true,
      message: translate("plugins.removeParentProduct"),
      handleClose: () => showModal(null),
      handleContinue
    });
  };

  const handleSubmit = async (value: ParentProductDetails) => {
    setIsLoading(true);
    await request({
      route: updateParentProductRoute(),
      body: toFormData(value),
      method: "POST",
      onSuccess: async () => {
        onEditModeIdChange(-1);
        await getParentProducts();
        pushNotification({ message: translate("request.parentProducts.updateSuccess"), type: "success" });
      },
      onFailure: () => pushNotification({ message: translate("request.parentProducts.updateFail"), type: "danger" }),
      onAny: () => setIsLoading(false)
    });
  };

  return {
    isLoading,
    handleSubmit,
    handleDelete
  };
};
