import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Loader } from "../common/progress/Loader";
import { DeleteInstructions } from "./models/DeleteInstructions";
import { PluginVersion } from "./models/PluginVersion";
import { Status } from "./models/Status";
import { useVersionActions } from "./hooks/useVersionActions";
import { useAuth0 } from "@auth0/auth0-react";
import { ExtendedUser } from "../identity/models/ExtendedUser";
import { usePlugin } from "./contexts/PluginContext";
import { useCopy } from "../../hooks/useCopy";
import { DeleteVersionButtonGroup } from "./DeleteVersionButtonGroup";
import { faFileDownload, faLocationArrow, faShareNodes } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useLocalization } from "../../contexts/LocalizationContext";

const statusBoxStyle: React.CSSProperties = {
  padding: "6px 4px",
  border: "1px solid lightgray",
  borderRadius: "5px",
  width: "120px",
  textAlign: "center"
};

type PluginVersionRowProps = {
  version: PluginVersion;
  route: string;
};

export const PluginVersionRow = ({ version, route }: PluginVersionRowProps) => {
  const { user } = useAuth0<ExtendedUser>();
  const { isLoading, download } = useVersionActions();
  const { showMessage, copy } = useCopy();
  const { plugin } = usePlugin();
  const { translate } = useLocalization();
  const navigate = useNavigate();
  const selectedAccount = user?.user_metadata?.selectedAccount;
  const includeChangelog = selectedAccount?.includeChangelogOnShare;
  const signedClass = version.isSigned ? "text-success" : "text-danger";
  const url = `${version?.downloadDetails?.appStoreAPIDownloadUrl}?includeChangelog=`;

  version.pluginDetails = plugin;

  const navigateToVersion = () => window.open(version?.downloadDetails?.downloadUrl, "_blank");

  if (isLoading) return <Loader />;

  return (
    <div className="d-flex justify-content-center align-items-center p-3 border-bottom">
      <p onClick={() => navigate(route)} className="m-0 fw-bold text-black-50 w-100 cursor-pointer">
        {version.versionNumber}
      </p>
      <div className="d-flex align-items-center">
        {version.downloadDetails?.downloadUrl && (
          <>
            {showMessage ? (
              <p className="m-0 text-success">{translate("versions.copied")}</p>
            ) : (
              <OverlayTrigger overlay={<Tooltip>{translate("versions.share")}</Tooltip>}>
                <FontAwesomeIcon
                  className="cursor-pointer me-1"
                  icon={faShareNodes}
                  onClick={() => copy(`${url}${!!includeChangelog}`)}
                />
              </OverlayTrigger>
            )}

            {version.isNavigationLink ? (
              <OverlayTrigger overlay={<Tooltip>{translate("versions.navigate")}</Tooltip>}>
                <FontAwesomeIcon className="cursor-pointer me-1" icon={faLocationArrow} onClick={navigateToVersion} />
              </OverlayTrigger>
            ) : (
              <OverlayTrigger
                overlay={
                  <Tooltip>
                    {translate("versions.isSignedDownload", {
                      download: version.isSigned ? translate("versions.signed") : translate("versions.unsigned")
                    })}
                  </Tooltip>
                }
              >
                <FontAwesomeIcon
                  className={`cursor-pointer ${signedClass} me-1`}
                  icon={faFileDownload}
                  onClick={() => download(version.versionId)}
                />
              </OverlayTrigger>
            )}
          </>
        )}
        <div className="me-2">
          <DeleteVersionButtonGroup
            hasIcon
            showOverlay
            version={version}
            popoverPlacement="bottom"
            futureInstructions={DeleteInstructions.All}
          />
        </div>
        <div style={statusBoxStyle}>{Status[version.status]}</div>
      </div>
    </div>
  );
};
