import { Form, Formik } from "formik";
import { PluginDetailsBase } from "./models/PluginDetailsBase";
import { PluginDetails as PluginModel } from "./models/PluginDetails";
import { pluginValidationSchema } from "./schemas/PluginValidationSchema";
import { ReactNode } from "react";
import { useLocalization } from "../../contexts/LocalizationContext";

type PluginDetailsProps = {
  plugin?: PluginModel;
  children: ReactNode;
};

export const PluginDetailsForm = ({ plugin, children }: PluginDetailsProps) => {
  const { translate } = useLocalization();

  const initialValues = {
    id: plugin?.id ?? -1,
    name: plugin?.name ?? "",
    iconUpload: null,
    supportUrl: plugin?.supportUrl ?? "",
    supportEmail: plugin?.supportEmail ?? "",
    description: plugin?.description ?? "<p><br></p>",
    documentation: plugin?.documentation ?? "<p><br></p>",
    categories: plugin?.categories ?? [],
    parentProductDetailsId: plugin?.parentProductDetailsId ?? 0,
    sharedParentProductIds: plugin?.sharedParentProductIds ?? [],
    paidFor: plugin?.paidFor ?? false,
    publishToRWSAppStore: plugin?.publishToRWSAppStore,
    mediaUrl: plugin?.iconDetails?.mediaUrl
  } as PluginDetailsBase & { mediaUrl?: string };

  return (
    <Formik<PluginDetailsBase>
      validationSchema={pluginValidationSchema(translate)}
      initialValues={initialValues}
      onSubmit={() => undefined}
      enableReinitialize
    >
      <Form>{children}</Form>
    </Formik>
  );
};
