import { Outlet, useParams } from "react-router-dom";
import { useFetch } from "../../../hooks/useFetch";
import { PluginContextProps } from "./models/PluginContextProps";
import { PluginDetails } from "../models/PluginDetails";
import { PluginResponse } from "../models/PluginResponse";
import {
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState
} from "react";
import { getPluginRoute } from "../../../apiRoutes";

type PluginContextProviderProps = { children?: ReactElement };

const PluginContext = createContext<PluginContextProps>({
  plugin: {} as PluginDetails,
  isLoading: true,
  categories: [],
  parentProducts: [],
  productDetails: [],
  manifestResult: {},
  handleLoadingChange: () => undefined,
  getPlugin: async () => undefined,
  onManifestResultChange: () => undefined,
  setManifestResultField: () => undefined
});

export const usePlugin = () => useContext(PluginContext);

export const PluginContextProvider = ({
  children
}: PluginContextProviderProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({} as PluginResponse);
  const [manifestResult, setManifestResult] = useState({});
  const { request } = useFetch();
  const { pluginId } = useParams();

  const getPlugin = useCallback(
    async (id?: number) => {
      setIsLoading(true);
      await request({
        route: getPluginRoute(id ?? pluginId),
        onSuccess: async (response) => setData(await response.json()),
        onAny: () => setIsLoading(false)
      });
    },
    [request, pluginId]
  );

  useEffect(() => {
    getPlugin();
  }, [getPlugin]);

  const handleLoadingChange = (value: boolean) => {
    setIsLoading(value);
  };

  const onManifestResultChange = (result: { [key: string]: boolean }) => {
    setManifestResult(result);
  };

  const setManifestResultField = (key: string, value: boolean) => {
    setManifestResult((prev) => ({ ...prev, [key]: value }));
  };

  const categories = data?.categoryDetails?.map((x) => ({
    value: `${x.id}`,
    text: x.name
  }));

  const parentProducts = data?.parentProducts?.map((x) => ({
    value: `${x.id}`,
    text: x.productName
  }));

  return (
    <PluginContext.Provider
      value={{
        ...data,
        categories,
        parentProducts,
        isLoading,
        manifestResult,
        onManifestResultChange,
        setManifestResultField,
        handleLoadingChange,
        getPlugin
      }}
    >
      {children ?? <Outlet />}
    </PluginContext.Provider>
  );
};
