import { Form, useFormikContext } from "formik";
import { Button, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { UserProfile } from "../identity/models/UserProfile";
import { UserRole } from "../identity/models/UserRole";
import { UsersTableEditableRow } from "./UsersTableEditableRow";
import { UsersTableReadonlyRow } from "./UsersTableReadonlyRow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAnglesDown } from "@fortawesome/free-solid-svg-icons";
import { usePreserve } from "../../contexts/PreserveContext";
import { useEffect, useState } from "react";
import { useLocalization } from "../../contexts/LocalizationContext";

type UsersTableProps = {
  users: UserProfile[];
  roles: UserRole[];
  editModeId: string;
  onDiscard: () => void;
  onEdit: (id: string) => void;
  onDismiss: (id: string) => void;
};

const defaultCapacity = 10;

export const UsersTable = ({ editModeId, users, roles, onEdit, onDismiss, onDiscard }: UsersTableProps) => {
  const [capacity, setCapacity] = useState(defaultCapacity);
  const { values, initialValues } = useFormikContext<UserProfile>();
  const { setCurrentState, setInitialState } = usePreserve();
  const { translate } = useLocalization();
  const newUsers = users.slice(0, capacity);

  useEffect(() => {
    setCurrentState(values);
  }, [values]);

  useEffect(() => {
    setInitialState(initialValues);
  }, [initialValues]);

  return (
    <Form>
      <Table striped bordered>
        <thead>
          <tr className="d-none d-xl-table-row">
            <th></th>
            <th>{translate("account.username")}</th>
            <th>{translate("common.email")}</th>
            <th>{translate("account.status")}</th>
            <th>{translate("account.assignedRole")}</th>
            <th colSpan={3}></th>
          </tr>
        </thead>
        <tbody>
          {newUsers.map((x, i) =>
            editModeId === x.id ? (
              <UsersTableEditableRow index={i + 1} key={x.id} user={x} roles={roles} onDiscard={onDiscard} />
            ) : (
              <UsersTableReadonlyRow
                user={x}
                key={x.id}
                users={users}
                index={i + 1}
                onEdit={onEdit}
                onDismiss={onDismiss}
              />
            )
          )}
          {users.length > defaultCapacity && (
            <>
              <tr>
                <td colSpan={8} className="text-center">
                  <OverlayTrigger
                    overlay={<Tooltip>{translate("common.expand", { capacity: `${defaultCapacity}` })}</Tooltip>}
                  >
                    <Button
                      className="p-0"
                      variant="transparent"
                      aria-label={translate("common.expand", { capacity: `${defaultCapacity}` })}
                      onClick={() => setCapacity((prev) => prev + defaultCapacity)}
                    >
                      <FontAwesomeIcon icon={faAngleDown} />
                    </Button>
                  </OverlayTrigger>
                </td>
              </tr>
              <tr>
                <td colSpan={8} className="text-center">
                  <OverlayTrigger overlay={<Tooltip>{translate("common.expandAll")}</Tooltip>}>
                    <Button
                      className="p-0"
                      variant="transparent"
                      aria-label={translate("common.expandAll")}
                      onClick={() => setCapacity(users.length)}
                    >
                      <FontAwesomeIcon icon={faAnglesDown} />
                    </Button>
                  </OverlayTrigger>
                </td>
              </tr>
            </>
          )}
        </tbody>
      </Table>
    </Form>
  );
};
