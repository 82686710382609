import { Form, Formik, useFormikContext } from "formik";
import { Button, Form as BootstrapForm } from "react-bootstrap";
import { useTheme } from "../../contexts/ThemeContext";
import { Loader } from "../common/progress/Loader";
import { AccountBase } from "../identity/models/AccountBase";
import { useAccount } from "./hooks/useAccount";
import { accountValidationSchema } from "./schemas/AccountValidationSchema";
import { usePreserve } from "../../contexts/PreserveContext";
import { useEffect } from "react";
import { useLocalization } from "../../contexts/LocalizationContext";

export const Account = () => {
  const { account, isLoading } = useAccount(true);
  const { translate } = useLocalization();

  if (isLoading) return <Loader />;

  const initialValues = {
    id: account?.id ?? "",
    name: account?.name ?? "",
    alias: account?.alias ?? ""
  };

  return (
    <>
      <hr className="d-block d-xl-none" aria-hidden />
      <h3 className="mb-3">{translate("account.account")}</h3>
      <Formik<AccountBase>
        initialValues={initialValues}
        validationSchema={accountValidationSchema(translate)}
        enableReinitialize
        onSubmit={() => undefined}
      >
        <AccountForm />
      </Formik>
    </>
  );
};

const AccountForm = () => {
  const formik = useFormikContext<AccountBase>();
  const { buttonStyle } = useTheme();
  const { isLoading, isGranted, handleSubmit } = useAccount(false);
  const { values, touched, errors, initialValues } = formik;
  const { handleChange, setTouched } = formik;
  const { setCurrentState, setInitialState } = usePreserve();
  const { translate } = useLocalization();

  useEffect(() => {
    setCurrentState(values);
  }, [values]);

  useEffect(() => {
    setInitialState(initialValues);
  }, [initialValues]);

  const handleSave = async () => {
    try {
      await accountValidationSchema(translate).validate(values, {
        abortEarly: false
      });
      setTouched({});
      await handleSubmit(values);
    } catch (errors) {
      setTouched({ alias: true, name: true });
    }
  };

  if (isLoading) return <Loader />;

  return (
    <Form>
      <BootstrapForm.Group className="mb-3">
        <BootstrapForm.Label htmlFor="alias">{translate("account.developerAlias")}</BootstrapForm.Label>
        <BootstrapForm.Control
          type="text"
          id="alias"
          name="alias"
          value={values.alias}
          onChange={handleChange}
          disabled={!isGranted}
        />
        {touched.alias && !!errors.alias && <span className="invalid-feedback d-inline">{errors.alias}</span>}
      </BootstrapForm.Group>
      <BootstrapForm.Group className="mb-3">
        <BootstrapForm.Label htmlFor="name">{translate("account.accountName")}</BootstrapForm.Label>
        <BootstrapForm.Control type="text" id="name" name="name" value={values.name} disabled />
      </BootstrapForm.Group>
      <Button style={buttonStyle} type="button" className="mb-3" disabled={!isGranted} onClick={handleSave}>
        {translate("common.save")}
      </Button>
    </Form>
  );
};
