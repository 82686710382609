import { Col, Row } from "react-bootstrap";
import { SideNavbar } from "../common/navigation/SideNavbar";
import { Outlet } from "react-router-dom";
import { MatchAlgorithm } from "../common/navigation/models/MatchAlgorithm";
import { TranslateFunction, useLocalization } from "../../contexts/LocalizationContext";
import { profileAccountsRoute, profileRoute, profileSettingsRoute } from "../../main/routes";

export const links = (translate: TranslateFunction) => [
  { name: translate("profile.profile"), route: profileRoute() },
  { name: translate("profile.accounts"), route: profileAccountsRoute() },
  { name: translate("profile.settings"), route: profileSettingsRoute() }
];

export const Layout = () => {
  const { translate } = useLocalization();

  return (
    <>
      <h1 aria-hidden>{translate("profile.profile")}</h1>
      <h2>{translate("profile.manageProfileSettings")}</h2>
      <hr aria-hidden className="w-100" />
      <Row>
        <SideNavbar items={links(translate)} matchAlgorithm={MatchAlgorithm.Equals} />
        <Col xs={12} xl={9}>
          <div className="h-100">{<Outlet />}</div>
        </Col>
      </Row>
    </>
  );
};
