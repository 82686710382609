import { Button, Image } from "react-bootstrap";
import EmptyResult from "../../resources/empty-result.png";
import { PluginVersion } from "./models/PluginVersion";
import { PluginVersionRow } from "./PluginVersionRow";
import { useTheme } from "../../contexts/ThemeContext";
import { useNavigate, useParams } from "react-router-dom";
import { newVersionRoute, versionRoute } from "../../main/routes";
import { useLocalization } from "../../contexts/LocalizationContext";

type VersionsProps = { versions: PluginVersion[] };

export const Versions = ({ versions }: VersionsProps) => {
  const { buttonStyle } = useTheme();
  const { pluginId } = useParams();
  const { translate } = useLocalization();
  const navigate = useNavigate();

  return !versions.length ? (
    <div className="d-flex flex-column align-items-center">
      <Image src={EmptyResult} />
      <h6 className="mt-2">{translate("versions.noVersionsFound")}</h6>
      <Button className="mt-2" style={buttonStyle} onClick={() => navigate(newVersionRoute(pluginId))}>
        {translate("common.addNew")}
      </Button>
    </div>
  ) : (
    <>
      {versions.map((x) => (
        <PluginVersionRow key={x.versionId} version={x} route={versionRoute(pluginId, x.versionId, x.status)} />
      ))}
      <Button style={buttonStyle} className="mt-2" onClick={() => navigate(newVersionRoute(pluginId))}>
        {translate("common.addNew")}
      </Button>
    </>
  );
};
