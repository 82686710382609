import { Formik } from "formik";
import { useUsers } from "../applicationsettings/hooks/useUsers";
import { Loader } from "../common/progress/Loader";
import { UserGrant } from "../identity/models/UserGrant";
import { InviteUserForm } from "./InviteUserForm";
import { userGrantValidationSchema } from "./schemas/UserGrantValidationSchema";
import { UsersTable } from "./UsersTable";
import { useLocalization } from "../../contexts/LocalizationContext";

export const Users = () => {
  const users = useUsers();
  const { data, editModeId, isLoading } = users;
  const { setEditModeId, handleLeave, getUsers, handleSubmit } = users;
  const { translate } = useLocalization();

  if (isLoading) return <Loader />;

  const editableItem = data?.userProfiles.find((item) => item.id === editModeId);

  const initialValues = {
    id: editableItem?.id ?? "",
    email: editableItem?.email ?? "",
    name: editableItem?.name ?? "",
    selectedRole: { id: editableItem?.selectedRole?.id ?? "", name: "" }
  };

  return (
    <Formik<UserGrant>
      initialValues={initialValues}
      validationSchema={userGrantValidationSchema(translate)}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      <>
        <hr className="d-block d-xl-none" aria-hidden />
        <h3 className="mb-3">{translate("settings.assignedUsers")}</h3>
        <UsersTable
          editModeId={editModeId}
          roles={data?.userRoles ?? []}
          users={data?.userProfiles ?? []}
          onDismiss={handleLeave}
          onDiscard={() => setEditModeId("")}
          onEdit={(id: string) => setEditModeId(id)}
        />
        <InviteUserForm roles={data?.userRoles ?? []} getUsers={getUsers} />
      </>
    </Formik>
  );
};
