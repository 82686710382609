import { useState } from "react";
import { useFeedback } from "../../../contexts/FeedbackContext";
import { useFetch } from "../../../hooks/useFetch";
import { useNameMappings } from "../contexts/NameMappingsContext";
import { NameMapping } from "../models/NameMapping";
import { toFormData } from "../../../utils";
import { useLocalization } from "../../../contexts/LocalizationContext";
import { deleteNameMappingRoute, updateNameMappingRoute } from "../../../apiRoutes";

export const useNameMappingsActions = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { onEditModeIdChange, getNames } = useNameMappings();
  const { request } = useFetch();
  const { pushNotification, showModal } = useFeedback();
  const { translate } = useLocalization();

  const handleDelete = (id: number) => {
    const handleContinue = async () => {
      showModal(null);
      setIsLoading(true);

      await request({
        route: deleteNameMappingRoute(id),
        method: "DELETE",
        onSuccess: async () => {
          await getNames();
          pushNotification({ message: translate("request.names.deleteSuccess"), type: "success" });
        },
        onFailure: () => pushNotification({ message: translate("request.names.deleteFail"), type: "danger" }),
        onAny: () => setIsLoading(false)
      });
    };

    showModal({
      isVisible: true,
      message: translate("settings.removeNameMapping"),
      handleClose: () => showModal(null),
      handleContinue
    });
  };

  const handleSubmit = async (value: NameMapping) => {
    setIsLoading(true);
    await request({
      route: updateNameMappingRoute(),
      body: toFormData(value),
      method: "POST",
      onSuccess: async () => {
        onEditModeIdChange(-1);
        await getNames();
        pushNotification({
          message: translate("request.names.updateSuccess", {
            pluginName: value.oldName,
            newName: value.newName
          }),
          type: "success"
        });
      },
      onFailure: () => pushNotification({ message: translate("request.names.updateFail"), type: "danger" }),
      onAny: () => setIsLoading(false)
    });
  };

  return {
    isLoading,
    handleDelete,
    handleSubmit
  };
};
