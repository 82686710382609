import { useAuth0 } from "@auth0/auth0-react";
import { useCallback } from "react";
import { useConfig } from "../contexts/ConfigContext";

type FetchParams = {
  route: string;
  body?: FormData | string | null;
  method?: "GET" | "POST" | "PUT" | "PATCH" | "DELETE";
  isLocalRequest?: boolean;
  contentType?: "application/json" | "multipart/form-data";
  onSuccess?: (response: Response) => void | Promise<void>;
  onFailure?: (response: Response) => void | Promise<void>;
  onError?: () => void | Promise<void>;
  onAny?: () => void;
};

export const useFetch = () => {
  const { configuration } = useConfig();
  const { getIdTokenClaims } = useAuth0();

  const request = useCallback(
    async ({ route, body, method, isLocalRequest, contentType, onSuccess, onFailure, onError, onAny }: FetchParams) => {
      const url = isLocalRequest ? route : configuration.apiUrl + route;

      try {
        const claims = await getIdTokenClaims();
        const token = claims?.__raw ?? "";
        const headers = {
          headers: { Authorization: `Bearer ${token}` } as any,
          method,
          body
        };

        if (contentType) headers.headers["Content-Type"] = contentType;

        const response = await fetch(url, headers);

        if (response.ok) {
          if (onSuccess) await onSuccess(response);
        } else {
          if (onFailure) onFailure(response);
        }
      } catch (error) {
        if (onError) await onError();
      } finally {
        if (onAny) onAny();
      }
    },
    [getIdTokenClaims, configuration.apiUrl]
  );

  return { request };
};
