import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { usePreserve } from "../../contexts/PreserveContext";
import { PluginVersion } from "./models/PluginVersion";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { useTheme } from "../../contexts/ThemeContext";
import { useNavigate, useParams } from "react-router-dom";
import { Status } from "./models/Status";
import { versionRoute } from "../../main/routes";

type VersionsSidebarListProps = { versions: PluginVersion[] };

export const VersionsSidebarList = ({ versions }: VersionsSidebarListProps) => {
  const { safeExecute } = usePreserve();
  const { theme } = useTheme();
  const { versionId, pluginId } = useParams();
  const navigate = useNavigate();

  const handleVersionNavigation = (versionId: number, status: Status) => {
    safeExecute(() => navigate(versionRoute(pluginId, versionId, status)));
  };

  return (
    <ul className="border-top p-0 mt-3" style={{ listStyle: "none" }}>
      {versions.map((x) => (
        <li
          key={x.versionId}
          className="d-flex justify-content-between align-items-center p-2 border-bottom cursor-pointer"
          onClick={() => handleVersionNavigation(x.versionId, x.status)}
        >
          <span>
            <FontAwesomeIcon
              icon={faCaretRight}
              className="me-2"
              style={{ color: theme.backgroundColor, opacity: versionId === `${x.versionId}` ? 1 : 0 }}
            />
            <h3 className="fs-6 d-inline fw-normal">
              <span aria-label="View version" />
              {`${x.versionNumber} ${x.supportedProductVersions}`}
            </h3>
          </span>
          <span className="border rounded p-1">{Status[x.status]}</span>
        </li>
      ))}
    </ul>
  );
};
