import { partial_ratio } from "fuzzball";
import { CategoryDetails } from "../../applicationsettings/models/CategoryDetails";
import { ProductDetails } from "../../applicationsettings/models/ProductDetails";
import { Account } from "../../identity/models/Account";
import { Status } from "../../plugins/models/Status";
import { AccountsFilter } from "../model/AccountsFilter";
import { removeHTMLTags } from "../../../utils";
import { SortType } from "../model/SortType";

const score = 85;

export const applyFilters = (
  filters: AccountsFilter,
  accunts: Account[],
  products: ProductDetails[],
  categories: CategoryDetails[]
) => {
  let filteredAccounts = [...accunts];

  if (filters.Categories.length) filteredAccounts = filterByCategory(filteredAccounts, filters.Categories, categories);
  if (filters.Products.length) filteredAccounts = filterByProducts(filteredAccounts, filters.Products, products);
  if (filters.Pricing.length) filteredAccounts = filterByPricing(filteredAccounts, filters.Pricing);
  if (filters.AppStatus.length) filteredAccounts = filterByAppStatus(filteredAccounts, filters.AppStatus);
  if (filters.AccountStatus.length) filteredAccounts = filterByAccountStatus(filteredAccounts, filters.AccountStatus);
  if (filters.AccountName) filteredAccounts = filterByAccountName(filteredAccounts, filters.AccountName);
  if (filters.AccountAlias) filteredAccounts = filterByAccountAlias(filteredAccounts, filters.AccountAlias);
  if (filters.AppName) filteredAccounts = filterByAppName(filteredAccounts, filters.AppName);
  if (filters.User) filteredAccounts = filterByUser(filteredAccounts, filters.User);
  if (filters.AccountNameSort) sortByAccountName(filteredAccounts, filters.AccountNameSort);
  if (filters.AccountAliasSort) sortByAccountAlias(filteredAccounts, filters.AccountAliasSort);
  if (filters.CreatedDateSort) sortByCreatedDate(filteredAccounts, filters.CreatedDateSort);
  if (filters.RecentActionSort) sortByRecentAction(filteredAccounts, filters.RecentActionSort);

  return filteredAccounts;
};

const filterByCategory = (accounts: Account[], categories: string[], categoryDetails: CategoryDetails[]) => {
  const categoryIds = categoryDetails.filter((x) => categories.some((y) => y === x.name)).map((x) => x.id);
  return accounts.filter((account) =>
    account.pluginDetails.some((plugin) => plugin.categories.some((category) => categoryIds.includes(category)))
  );
};

const filterByProducts = (accounts: Account[], products: string[], productDetails: ProductDetails[]) => {
  const productIds = productDetails.filter((x) => products.some((y) => y === x.productName)).map((x) => x.id);
  return accounts.filter((x) => x.pluginDetails.some((y) => productIds.includes(y.parentProductDetailsId)));
};

const filterByPricing = (accounts: Account[], pricing: string[]) => {
  return accounts.filter((x) => x.pluginDetails.some((y) => pricing.some((z) => (y.paidFor ? "Paid" : "Free") === z)));
};

const filterByAppStatus = (accounts: Account[], appStatus: string[]) => {
  return accounts.filter((x) => x.pluginDetails.some((y) => appStatus.some((z) => Status[y.status] === z)));
};

const filterByAccountStatus = (accounts: Account[], accountStatus: string[]) => {
  return accounts.filter((x) => accountStatus.some((y) => (x.publishToRWSAppStore ? "Private" : "Public") === y));
};

const filterByAccountName = (accounts: Account[], accountName: string) => {
  return accounts.filter((x) => partial_ratio(x.name, accountName) >= score);
};

const filterByAccountAlias = (accounts: Account[], accountAlias: string) => {
  return accounts.filter((x) => partial_ratio(x.alias, accountAlias) >= score);
};

const sortByAccountName = (accounts: Account[], accountName: SortType) => {
  if (accountName === "asc") accounts.sort(compareByName);
  else accounts.sort(compareByName).reverse();
};

const sortByAccountAlias = (accounts: Account[], accountAlias: SortType) => {
  if (accountAlias === "asc") accounts.sort(compareByAlias);
  else accounts.sort(compareByAlias).reverse();
};

const sortByCreatedDate = (accounts: Account[], createdDate: SortType) => {
  if (createdDate === "asc") {
    const known = accounts.filter((x) => !!x.createdAt);
    const unknown = accounts.filter((x) => !x.createdAt);
    known.sort(compareByCreatedAt);
    accounts = known.concat(unknown);
  } else {
    accounts.sort(compareByCreatedAt).reverse();
  }
};

const sortByRecentAction = (accounts: Account[], recentAction: SortType) => {
  if (recentAction === "asc") {
    const known = accounts.filter((x) => !!x.recentAction);
    const unknown = accounts.filter((x) => !x.recentAction);
    known.sort(compareByRecentAction);
    accounts = known.concat(unknown);
  } else {
    accounts.sort(compareByRecentAction).reverse();
  }
};

const filterByUser = (accounts: Account[], user: string) => {
  const newList = [];

  for (const account of accounts) {
    const usersList = account.userProfiles.filter((x) => {
      if (partial_ratio(x.email, user) >= score) return true;
      if (partial_ratio(x.name, user) >= score) return true;
    });

    if (usersList.length > 0) {
      account.userProfiles = usersList;
      newList.push(account);
    }
  }

  return newList;
};

const filterByAppName = (accounts: Account[], appName: string) => {
  const newList = [];

  for (const account of accounts) {
    const appsList = account.pluginDetails.filter((x) => {
      const description = removeHTMLTags(x.description);
      if (partial_ratio(x.name, appName) >= score) return true;
      if (partial_ratio(description, appName) >= score) return true;
    });

    if (appsList.length > 0) {
      account.pluginDetails = appsList;
      newList.push(account);
    }
  }

  return newList;
};

const compareByName = (first: Account, second: Account) => {
  const firstName = first.name.toUpperCase();
  const secondName = second.name.toUpperCase();
  if (firstName < secondName) return -1;
  return firstName > secondName ? 1 : 0;
};

const compareByAlias = (first: Account, second: Account) => {
  const firstAlias = first.alias.toUpperCase();
  const secondAlias = second.alias.toUpperCase();
  if (firstAlias < secondAlias) return -1;
  return firstAlias > secondAlias ? 1 : 0;
};

const compareByRecentAction = (first: Account, second: Account) => {
  const firstDate = first.recentAction;
  const secondDate = second.recentAction;
  if (firstDate < secondDate || (!firstDate && secondDate)) return -1;
  return firstDate > secondDate || (firstDate && !secondDate) ? 1 : 0;
};

const compareByCreatedAt = (first: Account, second: Account) => {
  const firstDate = first.createdAt;
  const secondDate = second.createdAt;
  if (firstDate < secondDate || (!firstDate && secondDate)) return -1;
  return firstDate > secondDate || (firstDate && !secondDate) ? 1 : 0;
};
