import { useState } from "react";
import { useFetch } from "./useFetch";
import { useFeedback } from "../contexts/FeedbackContext";

const filenameKey = "filename=";

export const useDownload = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { request } = useFetch();
  const { pushNotification } = useFeedback();

  const handleDownload = async (route: string, body?: string, contentType?: "application/json") => {
    setIsLoading(true);
    const onSuccess = async (response: Response) => {
      if (response.ok) {
        const blob = await response.blob();

        if (blob) {
          const filename = getFileNameFromHeaders(response.headers);
          const url = window.URL.createObjectURL(blob);
          const anchor = document.createElement("a");

          anchor.href = url;
          anchor.download = filename as string;
          document.body.appendChild(anchor);
          anchor.click();

          window.URL.revokeObjectURL(url);
          document.body.removeChild(anchor);
        }
      }
    };

    await request({
      route,
      body,
      method: body ? "POST" : "GET",
      contentType,
      onSuccess,
      onFailure: async (response) => pushNotification({ message: await response.text(), type: "danger" }),
      onAny: () => setIsLoading(false)
    });
  };

  return { handleDownload, isLoading };
};

const getFileNameFromHeaders = (headers: Headers) => {
  const contentDisposition = headers.get("Content-Disposition");
  const headerValues = contentDisposition?.split(";");
  const target = headerValues?.find((x) => x.includes(filenameKey));
  return target?.split(filenameKey).at(1)?.replaceAll("\u0022", "");
};
