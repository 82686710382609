import { Status } from "../components/plugins/models/Status";
import { routeStatusMap } from "../components/plugins/models/StatusHelper";
import { stringFormat } from "../utils";

const baseUrl = process.env.PUBLIC_URL;

const profileRouteTemplate = "/profile";
const profileAccountsRouteTemplate = "/profile/accounts";
const profileSettingsRouteTemplate = "/profile/settings";

const administrationCategoriesRouteTemplate = "/administration/settings/categories";
const administrationProductsRouteTemplate = "/administration/settings/products";
const administrationParentProductsRouteTemplate = "/administration/settings/products/parents";

const accountRouteTemplate = "/settings/account";
const accountUsersRouteTemplate = "/settings/account/users";
const accountSettingsRouteTemplate = "/settings/account/settings";
const accountPluginExportRouteTemplate = "/settings/account/export";
const accountNameMappingsRouteTemplate = "/settings/account/names";

const versionIndexTemplate = `${baseUrl}/plugins/{0}/versions`;
const versionTemplate = `${baseUrl}/plugins/{0}/versions/{1}/{2}`;
const pluginTemplate = `${baseUrl}/plugins/{0}/{1}`;

export const profileRoute = () => profileRouteTemplate;

export const profileAccountsRoute = () => profileAccountsRouteTemplate;

export const profileSettingsRoute = () => profileSettingsRouteTemplate;

export const administrationCategoriesRoute = () => administrationCategoriesRouteTemplate;

export const administrationProductsRoute = () => administrationProductsRouteTemplate;

export const administrationParentProductsRoute = () => administrationParentProductsRouteTemplate;

export const accountRoute = () => accountRouteTemplate;

export const accountUsersRoute = () => accountUsersRouteTemplate;

export const accountSettingsRoute = () => accountSettingsRouteTemplate;

export const accountExportRoute = () => accountPluginExportRouteTemplate;

export const accountNameMappingsRoute = () => accountNameMappingsRouteTemplate;

export const pluginRoute = (pluginId: any, status: Status) =>
  stringFormat(pluginTemplate, pluginId, routeStatusMap.get(status));

export const pluginCommentsRoute = (pluginId: any) => stringFormat(pluginTemplate, pluginId, "comments");

export const pluginLogsRoute = (pluginId: any) => stringFormat(pluginTemplate, pluginId, "logs");

export const newPluginRoute = () => `${baseUrl}/plugins/new`;

export const newVersionRoute = (pluginId: any) => stringFormat(pluginTemplate, pluginId, "versions/new");

export const versionIndexRoute = (pluginId: any) => stringFormat(versionIndexTemplate, pluginId);

export const versionRoute = (pluginId: any, versionId: any, status: Status) =>
  stringFormat(versionTemplate, pluginId, versionId, routeStatusMap.get(status));

export const versionCommentsRoute = (pluginId: any, versionId: any) =>
  stringFormat(versionTemplate, pluginId, versionId, "comments");
