import { TranslateFunction } from "../../../contexts/LocalizationContext";
import { ExtendedUser } from "./ExtendedUser";
import { UserProfile } from "./UserProfile";
import { Roles } from "./UserRole";

export class UsersGroup {
  private currentUser: ExtendedUser;
  private users: UserProfile[];

  constructor(currentUser: ExtendedUser, users: UserProfile[]) {
    this.currentUser = currentUser;
    this.users = users;
  }

  getEditRoleMessage(user: UserProfile, translate: TranslateFunction): string {
    if (this.currentUser.user_metadata?.id === user.id) {
      return translate("account.cannotEditYourRole");
    }

    return this.canEditUserRole(user)
      ? translate("account.editRole")
      : translate("account.cannotEditThisUserRole");
  }

  getDismissUserMessage(
    user: UserProfile,
    translate: TranslateFunction
  ): string {
    if (this.currentUser.user_metadata?.id === user.id) {
      return this.canLeaveAccount()
        ? translate("account.leave")
        : translate("account.cannotLeaveThisAccount");
    }

    return this.canRemoveUser(user)
      ? translate("account.remove")
      : translate("account.cannotRemoveThisUser");
  }

  canBeRemoved(user: UserProfile): boolean {
    return this.currentUser.user_metadata?.id === user.id
      ? this.canLeaveAccount()
      : this.canRemoveUser(user);
  }

  canLeaveAccount(): boolean {
    const filtered = this.users.filter(
      (x) => x.id !== this.currentUser.user_metadata?.id
    );

    if (
      this.currentUser.user_metadata?.selectedRole?.name === Roles.Administrator
    ) {
      return filtered.some(
        (x) =>
          x?.selectedRole?.name === Roles.Administrator ||
          x?.selectedRole?.name === Roles.SystemAdministrator
      );
    }

    return (
      filtered.some(
        (x) => x?.selectedRole?.name === Roles.SystemAdministrator
      ) ||
      this.currentUser.user_metadata?.selectedRole?.name === Roles.Developer
    );
  }

  canRemoveUser(user: UserProfile): boolean {
    if (
      this.currentUser.user_metadata?.selectedRole?.name === Roles.Administrator
    ) {
      return (
        user?.selectedRole?.name === Roles.Administrator ||
        user?.selectedRole?.name === Roles.Developer
      );
    }

    return (
      this.currentUser.user_metadata?.selectedRole?.name ===
      Roles.SystemAdministrator
    );
  }

  canInviteUser(user: UserProfile): boolean {
    return (
      this.currentUser.user_metadata?.selectedRole?.name !== Roles.Developer &&
      !user.isConfirmed
    );
  }

  canEditUserRole(user: UserProfile): boolean {
    if (
      this.currentUser.user_metadata?.selectedRole?.name === Roles.Administrator
    ) {
      return user?.selectedRole?.name !== Roles.SystemAdministrator;
    }

    return (
      this.currentUser.user_metadata?.selectedRole?.name ===
        Roles.SystemAdministrator &&
      this.currentUser.user_metadata?.id !== user.id
    );
  }
}
