import { useState } from "react";
import { Container, Dropdown, Nav, Navbar } from "react-bootstrap";
import { useUser } from "../../contexts/UserContext";
import { NotificationsToggler } from "../notifications/NotificationsToggler";
import { usePreserve } from "../../contexts/PreserveContext";
import { useLocalization } from "../../contexts/LocalizationContext";
import { AdminNotificationsBox } from "../notifications/AdminNotificationsBox";

const inheritStyle: React.CSSProperties = { color: "inherit" };
const menuStyle: React.CSSProperties = { width: 420, top: 40, right: -200 };

export const AdminNavigation = () => {
  const [isToggled, setIsToggled] = useState(false);
  const { logout } = useUser();
  const { safeExecute } = usePreserve();
  const baseUrl = process.env.PUBLIC_URL;
  const { translate } = useLocalization();

  return (
    <Navbar expand="lg">
      <Container>
        <h2
          style={inheritStyle}
          className="cursor-pointer"
          onClick={() => safeExecute(() => (window.location.href = `${baseUrl}/administration`))}
        >
          {translate("account.appStoreManagerAdministration")}
        </h2>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link onClick={() => safeExecute(() => (window.location.href = `${baseUrl}/`))} style={inheritStyle}>
              {translate("account.appStoreManager")}
            </Nav.Link>
            <Nav.Link
              onClick={() =>
                safeExecute(() => (window.location.href = `${baseUrl}/administration/settings/categories`))
              }
              style={inheritStyle}
            >
              {translate("common.settings")}
            </Nav.Link>
            <Nav.Link onClick={() => safeExecute(() => logout())} style={inheritStyle}>
              {translate("account.logout")}
            </Nav.Link>
            <Dropdown
              drop="start"
              autoClose="outside"
              className="d-flex align-items-center ms-2"
              onToggle={() => setIsToggled((prev) => !prev)}
            >
              <Dropdown.Toggle style={inheritStyle} as={NotificationsToggler} isAdministratorView></Dropdown.Toggle>
              <Dropdown.Menu className="p-0" style={menuStyle}>
                {isToggled && <AdminNotificationsBox />}
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
