import * as Yup from "yup";
import { TranslateFunction } from "../../../contexts/LocalizationContext";

export const inviteUserValidationSchema = (translate : TranslateFunction) => Yup.object().shape({
  id: Yup.string(),
  email: Yup.string()
    .email(translate("validation.invalidEmail"))
    .required(translate("validation.fieldRequired")),
  selectedRole: Yup.object().shape({
    id: Yup.string().required(translate("validation.fieldRequired"))
  })
});
