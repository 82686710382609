import { useFormikContext } from "formik";
import { Modal, Form as BootstrapForm, Button, Spinner } from "react-bootstrap";
import { useTheme } from "../../contexts/ThemeContext";
import { SelectItem } from "../common/inputs/models/SelectItem";
import { Select } from "../common/inputs/Select";
import { UserInvitation } from "../identity/models/UserInvitation";
import { useInvite } from "./hooks/useInvite";
import { inviteUserValidationSchema } from "./schemas/InviteUserValidationSchema";
import { useLocalization } from "../../contexts/LocalizationContext";

type InviteUserModalProps = {
  roles: SelectItem[];
  onClose: () => void;
  getUsers: () => Promise<void>;
};

export const InviteUserModal = ({ roles, onClose, getUsers }: InviteUserModalProps) => {
  const formik = useFormikContext<UserInvitation>();
  const { isLoading, handleInvite } = useInvite();
  const { buttonStyle } = useTheme();
  const { values, touched, errors, handleChange, setTouched } = formik;
  const { translate } = useLocalization();

  const handleClick = async () => {
    try {
      await inviteUserValidationSchema(translate).validate(values, { abortEarly: false });
      setTouched({});
      await handleInvite(values, getUsers);
      onClose();
    } catch (errors) {
      setTouched({ email: true, selectedRole: { id: true } });
    }
  };

  return (
    <Modal size="lg" show={true} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{translate("settings.inviteUser")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <BootstrapForm.Group className="mb-3">
          <BootstrapForm.Label htmlFor="email">{translate("common.email")}</BootstrapForm.Label>
          <BootstrapForm.Control
            type="email"
            id="email"
            name="email"
            value={values.email}
            onChange={handleChange}
            disabled={isLoading}
          />
          {touched.email && !!errors.email && <span className="invalid-feedback d-inline">{errors.email}</span>}
        </BootstrapForm.Group>
        <BootstrapForm.Group className="mb-3">
          <BootstrapForm.Label htmlFor="selectedRoleId">{translate("common.role")}</BootstrapForm.Label>
          <Select
            name="selectedRole.id"
            disabled={isLoading}
            value={values.selectedRole.id}
            onChange={handleChange}
            items={roles}
          />
          {touched.selectedRole?.id && !!errors.selectedRole?.id && (
            <span className="invalid-feedback d-inline">{errors.selectedRole?.id}</span>
          )}
        </BootstrapForm.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClick} style={{ ...buttonStyle, width: 100 }} disabled={isLoading}>
          {isLoading ? <Spinner size="sm" /> : translate("settings.invite")}
        </Button>
        <Button variant="secondary" onClick={onClose}>
          {translate("common.close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
