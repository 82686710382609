import { partial_ratio } from "fuzzball";
import { removeHTMLTags } from "../../../utils";
import { PluginDetails } from "../models/PluginDetails";
import { PluginsFilters } from "../models/PluginsFilters";
import { Status } from "../models/Status";
import { PluginVersion } from "../models/PluginVersion";
import { ProductDetails } from "../../applicationsettings/models/ProductDetails";

export const applyPluginsFilters = (filters: PluginsFilters, products: ProductDetails[], plugins: PluginDetails[]) => {
  let filteredPlugins = [...plugins];

  if (filters.Query) filteredPlugins = filterByQuery(filteredPlugins, filters.Query);
  if (filters.Status) filteredPlugins = filterByStatus(filteredPlugins, filters.Status);
  if (filters.Product) filteredPlugins = filterByProduct(filteredPlugins, products, filters.Product);

  return filteredPlugins;
};

const filterByQuery = (plugins: PluginDetails[], query: string) => {
  const score = 85;

  return plugins.filter((p) => {
    const description = removeHTMLTags(p.description);
    const developer = p.developerDetails.developerName;

    if (partial_ratio(p.name, query) >= score) return true;
    if (partial_ratio(developer, query) >= score) return true;
    if (partial_ratio(description, query) >= score) return true;
  });
};

const filterByStatus = (plugins: PluginDetails[], status: string) => {
  return plugins.filter((x) => Status[x.status].includes(status));
};

const filterByProduct = (plugins: PluginDetails[], products: ProductDetails[], product: string) => {
  const productId = products.find((x) => x.productName === product)?.id;
  if (!productId) return plugins;
  return plugins.filter((x) => isVersionMatch(x.pluginVersions, productId));
};

const isVersionMatch = (versions: PluginVersion[], productId: number) => {
  return versions.some((x) => isProductMatch(x.supportedProducts, productId));
};

const isProductMatch = (products: number[], productId: number) => {
  return products.some((id) => id === productId);
};
