import { faAngleDown, faAnglesDown, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, useFormikContext } from "formik";
import { Button, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { useTheme } from "../../contexts/ThemeContext";
import { Loader } from "../common/progress/Loader";
import { useParentProducts } from "./contexts/ParentProductsContext";
import { useParentProductsActions } from "./hooks/useParentProductsActions";
import { ParentProductDetails } from "./models/ParentProductDetails";
import { ParentProductsTableEditableRow } from "./ParentProductsTableEditableRow";
import { ParentProductsTableReadonlyRow } from "./ParentProductsTableReadonlyRow";
import { parentProductValidationSchema } from "./schemas/ParentProductValidationSchema";
import { useEffect } from "react";
import { usePreserve } from "../../contexts/PreserveContext";
import { useLocalization } from "../../contexts/LocalizationContext";

const defaultCapacity = 10;

export const ParentProductsTable = () => {
  const parents = useParentProducts();
  const formik = useFormikContext<ParentProductDetails>();
  const { theme, buttonStyle } = useTheme();
  const { isLoading, handleSubmit, handleDelete } = useParentProductsActions();
  const { values, initialValues, setTouched } = formik;
  const { editModeId, parentProducts, capacity } = parents;
  const { onCapacityChange, onEditModeIdChange } = parents;
  const { safeExecute, setCurrentState, setInitialState } = usePreserve();
  const { translate } = useLocalization();

  useEffect(() => {
    setCurrentState(values);
  }, [values]);

  useEffect(() => {
    setInitialState(initialValues);
  }, [initialValues]);

  const handleAdd = () => safeExecute(() => onEditModeIdChange(0));

  const handleClick = async () => {
    try {
      await parentProductValidationSchema(translate).validate(values, {
        abortEarly: false
      });
      setTouched({});
      await handleSubmit(values);
    } catch (errors) {
      setTouched({ productName: true, supportedFileExtensionsIds: true });
    }
  };

  if (isLoading) return <Loader />;

  const newParentProducts = parentProducts.slice(0, capacity);

  return (
    <Form>
      <Table striped bordered>
        <thead>
          <tr className="d-none d-xl-table-row">
            <th>{translate("plugins.id")}</th>
            <th>{translate("plugins.productName")}</th>
            <th>{translate("plugins.supportedFileExtensions")}</th>
            <th colSpan={2} className="text-center">
              <OverlayTrigger overlay={<Tooltip>{translate("common.addNew")}</Tooltip>}>
                <Button
                  variant="transparent"
                  className="p-0"
                  aria-label={translate("common.addNew")}
                  onClick={handleAdd}
                >
                  <FontAwesomeIcon icon={faPlusCircle} style={{ color: theme.backgroundColor }} />
                </Button>
              </OverlayTrigger>
            </th>
          </tr>
        </thead>
        <tbody>
          {newParentProducts.map((x, i) =>
            x.id === editModeId ? (
              <ParentProductsTableEditableRow key={x.id} index={i + 1} handleSave={handleClick} />
            ) : (
              <ParentProductsTableReadonlyRow key={x.id} item={x} index={i + 1} onDelete={() => handleDelete(x.id)} />
            )
          )}
          {editModeId === 0 && (
            <ParentProductsTableEditableRow index={parentProducts.length + 1} handleSave={handleClick} />
          )}
          {newParentProducts.length ? (
            parentProducts.length > defaultCapacity && (
              <>
                <tr>
                  <td colSpan={5} className="text-center">
                    <OverlayTrigger
                      overlay={<Tooltip>{translate("common.expand", { capacity: `${defaultCapacity}` })}</Tooltip>}
                    >
                      <Button
                        className="p-0"
                        variant="transparent"
                        aria-label={translate("common.expand", { capacity: `${defaultCapacity}` })}
                        onClick={() => onCapacityChange(defaultCapacity)}
                      >
                        <FontAwesomeIcon icon={faAngleDown} />
                      </Button>
                    </OverlayTrigger>
                  </td>
                </tr>
                <tr>
                  <td colSpan={5} className="text-center">
                    <OverlayTrigger overlay={<Tooltip>{translate("common.expandAll")}</Tooltip>}>
                      <Button
                        className="p-0"
                        variant="transparent"
                        aria-label={translate("common.expandAll")}
                        onClick={() => onCapacityChange(parentProducts.length)}
                      >
                        <FontAwesomeIcon icon={faAnglesDown} />
                      </Button>
                    </OverlayTrigger>
                  </td>
                </tr>
              </>
            )
          ) : (
            <>
              {editModeId === -1 && (
                <tr>
                  <td colSpan={5} className="text-center align-middle">
                    {translate("account.noAccountsFound")}
                  </td>
                </tr>
              )}
            </>
          )}
        </tbody>
      </Table>
      <Button style={buttonStyle} onClick={handleAdd}>
        {translate("common.addNew")}
      </Button>
    </Form>
  );
};
